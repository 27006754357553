// ISOVISION ECRANS ------------------------------------------------------------------- //

export const AR_W_ANALYSE_DE_RISQUE_ARBO = "AR_W_ANALYSE_DE_RISQUE_ARBO";
export const AR_W_MODELE_ANALYSE_RISQUE = "AR_W_MODELE_ANALYSE_RISQUE";
export const AS_W_ASPECT_PRIORITE = "AS_W_ASPECT_PRIORITE";
export const AS_W_FACTEUR_CORRECTION = "AS_W_FACTEUR_CORRECTION";
export const AS_W_IMPACT = "AS_W_IMPACT";
export const AS_W_INTRANT_EXTRANT = "AS_W_INTRANT_EXTRANT";
export const AS_W_PRIORITE = "AS_W_PRIORITE";
export const AS_W_TRAITEMENT_ASPECT = "AS_W_TRAITEMENT_ASPECT";
export const AS_W_TYPE_COMPOSANTE = "AS_W_TYPE_COMPOSANTE";
export const AS_W_TYPE_CRITERE = "AS_W_TYPE_CRITERE";
export const AS_W_TYPE_EXTRANT = "AS_W_TYPE_EXTRANT";
export const AS_W_TYPE_IMPACT = "AS_W_TYPE_IMPACT";
export const AS_W_TYPE_PARAMETRE = "AS_W_TYPE_PARAMETRE";
export const AS_WRP_ANALYSE_DES_IMPACTS = "AS_WRP_ANALYSE_DES_IMPACTS";
export const AS_WRP_ARBORESCENCE_COMPLETE = "AS_WRP_ARBORESCENCE_COMPLETE";
export const AS_WRP_FICHE_INTRANT_EXTRANT = "AS_WRP_FICHE_INTRANT_EXTRANT";
export const AS_WRP_MANAGEMENT_ASPECTS_ENV2 = "AS_WRP_MANAGEMENT_ASPECTS_ENV2";
export const AS_WRP_SOMMAIRE_ASPECTS_ENV = "AS_WRP_SOMMAIRE_ASPECTS_ENV";
export const AS_WRP_SOMMAIRE_ASPECTS_ENV_VOLET =
    "AS_WRP_SOMMAIRE_ASPECTS_ENV_VOLET";
export const AS_WRP_SOMMAIRE_IN_EXTRANT = "AS_WRP_SOMMAIRE_IN_EXTRANT";
export const AS_WRP_STRUCTURE_PROCEDE_ACTIVITE =
    "AS_WRP_STRUCTURE_PROCEDE_ACTIVITE";
export const AU_W_AUDIT_REALISATION = "AU_W_AUDIT_REALISATION";
export const AU_W_CADENASSAGE = "AU_W_CADENASSAGE";
export const AU_W_CADENASSAGE_APPROBATEUR = "AU_W_CADENASSAGE_APPROBATEUR";
export const AU_W_ELEMENT_INSPECTION_STANDARD =
    "AU_W_ELEMENT_INSPECTION_STANDARD";
export const AU_W_EMPLACEMENTS = "AU_W_EMPLACEMENTS";
export const AU_W_ENTITE_AUDITE = "AU_W_ENTITE_AUDITE";
export const AU_W_INSP_DETAIL = "AU_W_INSP_DETAIL";
export const AU_W_INSPECTION = "AU_W_INSPECTION";
export const AU_W_LISTE_EMPLACEMENT = "AU_W_LISTE_EMPLACEMENT";
export const AU_W_LISTE_EQUIPEMENT = "AU_W_LISTE_EQUIPEMENT";
export const AU_W_LISTE_PREPARATION = "AU_W_LISTE_PREPARATION";
export const AU_W_LISTE_TRAVAUX = "AU_W_LISTE_TRAVAUX";
export const AU_W_LISTE_VERIFICATION = "AU_W_LISTE_VERIFICATION";
export const AU_W_LISTE_VEROUILLAGE = "AU_W_LISTE_VEROUILLAGE";
export const AU_W_ROLE_AUDIT = "AU_W_ROLE_AUDIT";
export const AU_W_SAISIE_RAPIDE_ARTICLE = "AU_W_SAISIE_RAPIDE_ARTICLE";
export const AU_W_SAISIE_RAPIDE_ELEMENT = "AU_W_SAISIE_RAPIDE_ELEMENT";
export const AU_W_SAISIE_RAPIDE_QUESTION = "AU_W_SAISIE_RAPIDE_QUESTION";
export const AU_W_TRAITEMENT_DEMANDE = "AU_W_TRAITEMENT_DEMANDE";
export const AU_W_TYPE_DEMANDE = "AU_W_TYPE_DEMANDE";
export const AU_W_TYPE_INSPECTION = "AU_W_TYPE_INSPECTION";
export const AU_WR_INDICATEURS_PERFORMANCE = "AU_WR_INDICATEURS_PERFORMANCE";
export const AU_WRP_ARTICLES_VISES = "AU_WRP_ARTICLES_VISES";
export const AU_WRP_ASSOCIATION_ARTICLE_QUESTION =
    "AU_WRP_ASSOCIATION_ARTICLE_QUESTION";
export const AU_WRP_ASSOCIATION_ELEMENT_ARTICLE =
    "AU_WRP_ASSOCIATION_ELEMENT_ARTICLE";
export const AU_WRP_ASSOCIATION_NORME_ELEMENT =
    "AU_WRP_ASSOCIATION_NORME_ELEMENT";
export const AU_WRP_FORMULAIRE_INSPECTION = "AU_WRP_FORMULAIRE_INSPECTION";
export const AU_WRP_FORMULAIRE_INSPECTION_VIERGE =
    "AU_WRP_FORMULAIRE_INSPECTION_VIERGE";
export const AU_WRP_LISTE_DEMANDES = "AU_WRP_LISTE_DEMANDES";
export const AU_WRP_RAPPORT_INSPECTION = "AU_WRP_RAPPORT_INSPECTION";
export const AU_WRP_STATISTIQUE_INSPECTION = "AU_WRP_STATISTIQUE_INSPECTION";
export const CO_W_ACTION_CORRECTIVE_PREVENTIVE =
    "CO_W_ACTION_CORRECTIVE_PREVENTIVE";
export const CO_W_ACTIVITE_SURVEILLANCE = "CO_W_ACTIVITE_SURVEILLANCE";
export const CO_W_ACTIVITE_SURVEILLANCE_SECTION_PARAM =
    "CO_W_ACTIVITE_SURVEILLANCE_SECTION_PARAM";
export const CO_W_ARTICLE_ISO = "CO_W_ARTICLE_ISO";
export const CO_W_AUDIT = "CO_W_AUDIT";
export const CO_W_BANQUE_QUESTION = "CO_W_BANQUE_QUESTION";
export const CO_W_CATEGORIE_COUT = "CO_W_CATEGORIE_COUT";
export const CO_W_CAUSE_FONDAMENTALE = "CO_W_CAUSE_FONDAMENTALE";
export const CO_W_CAUSE_IMMEDIATE = "CO_W_CAUSE_IMMEDIATE";
export const CO_W_CHANGEMENT_LIGNE_DE_PRODUIT =
    "CO_W_CHANGEMENT_LIGNE_DE_PRODUIT";
export const CO_W_CHOIX_REPONSE = "CO_W_CHOIX_REPONSE";
export const CO_W_CONFIG_MODULE_NC = "CO_W_CONFIG_MODULE_NC";
export const CO_W_DEPASSEMENT = "CO_W_DEPASSEMENT";
export const CO_W_ECHANTILLON = "CO_W_ECHANTILLON";
export const CO_W_DEROGATION = "CO_W_DEROGATION";
export const CO_W_DISPOSITION = "CO_W_DISPOSITION";
export const CO_W_DUPLIQUER_PERSO_NC = "CO_W_DUPLIQUER_PERSO_NC";
export const CO_W_ECHANTILLION = "CO_W_ECHANTILLION";
export const CO_W_ELEMENT_AUDITABLE = "CO_W_ELEMENT_AUDITABLE";
export const CO_W_ENQUETE_MODELE = "CO_W_ENQUETE_MODELE";
export const CO_W_EQUIPE_TRAVAIL = "CO_W_EQUIPE_TRAVAIL";
export const CO_W_FORMULE = "CO_W_FORMULE";
export const CO_W_FREQUENCE = "CO_W_FREQUENCE";
export const CO_W_HISTO_MODIF_ECHANTILLON = "CO_W_HISTO_MODIF_ECHANTILLON";
export const CO_W_HISTO_SERVICE_EXTERNE = "CO_W_HISTO_SERVICE_EXTERNE";
export const CO_W_IMPORTATION_NC_RADF = "CO_W_IMPORTATION_NC_RADF";
export const CO_W_INTERVENANT_EXTERNE = "CO_W_INTERVENANT_EXTERNE";
export const CO_W_LISTE_CHOIX_1 = "CO_W_LISTE_CHOIX_1";
export const CO_W_LISTE_CHOIX_10 = "CO_W_LISTE_CHOIX_10";
export const CO_W_LISTE_CHOIX_2 = "CO_W_LISTE_CHOIX_2";
export const CO_W_LISTE_CHOIX_3 = "CO_W_LISTE_CHOIX_3";
export const CO_W_LISTE_CHOIX_4 = "CO_W_LISTE_CHOIX_4";
export const CO_W_LISTE_CHOIX_5 = "CO_W_LISTE_CHOIX_5";
export const CO_W_LISTE_CHOIX_6 = "CO_W_LISTE_CHOIX_6";
export const CO_W_LISTE_CHOIX_7 = "CO_W_LISTE_CHOIX_7";
export const CO_W_LISTE_CHOIX_8 = "CO_W_LISTE_CHOIX_8";
export const CO_W_LISTE_CHOIX_9 = "CO_W_LISTE_CHOIX_9";
export const CO_W_LISTE_CHOIX_PERSO_DETAIL = "CO_W_LISTE_CHOIX_PERSO_DETAIL";
export const CO_W_MAINTENANCE_CLASSE_N_CONFORMITE =
    "CO_W_MAINTENANCE_CLASSE_N_CONFORMITE";
export const CO_W_MAINTENANCE_PRODUITS = "CO_W_MAINTENANCE_PRODUITS";
export const CO_W_MAINTENANCE_TYPE_NON_CONFORMITE =
    "CO_W_MAINTENANCE_TYPE_NON_CONFORMITE";
export const CO_W_MAINTENANCE_TYPE_TRAITEMENT =
    "CO_W_MAINTENANCE_TYPE_TRAITEMENT";
export const CO_W_METHODE = "CO_W_METHODE";
export const CO_W_N_C_MAINTENANCE_ETAPE = "CO_W_N_C_MAINTENANCE_ETAPE";
export const CO_W_N_C_MAINTENANCE_ORIGINE = "CO_W_N_C_MAINTENANCE_ORIGINE";
export const CO_W_NON_CONFORMITE = "CO_W_NON_CONFORMITE";
export const CO_W_NON_CONFORMITE_CRIT = "CO_W_NON_CONFORMITE_CRIT";
export const CO_W_NON_CONFORMITE_AUTRES = "CO_W_NON_CONFORMITE_AUTRES";
export const CO_W_NON_CONFORMITE_INCIDENTS = "CO_W_NON_CONFORMITE_INCIDENTS";
export const CO_W_NON_CONFORMITE_OPPORTUNITE =
    "CO_W_NON_CONFORMITE_OPPORTUNITE";
export const CO_W_NON_CONFORMITE_PRODUIT = "CO_W_NON_CONFORMITE_PRODUIT";
export const CO_W_NORME_ISO = "CO_W_NORME_ISO";
export const CO_W_PARTIE_DU_CORPS = "CO_W_PARTIE_DU_CORPS";
export const CO_W_PROBABILITES_RECURRENCE = "CO_W_PROBABILITES_RECURRENCE";
export const CO_W_PROTECT_IMPORTATION = "CO_W_PROTECT_IMPORTATION";
export const CO_W_QUART_DE_TRAVAIL = "CO_W_QUART_DE_TRAVAIL";
export const CO_W_RAPPORT_NC_HEURES_TRAVAILLEES =
    "CO_W_RAPPORT_NC_HEURES_TRAVAILLEES";
export const CO_W_REGLE_IMPORTATION = "CO_W_REGLE_IMPORTATION";
export const CO_W_RENOMMER_ECRAN_MENU = "CO_W_RENOMMER_ECRAN_MENU";
export const CO_W_RENOMMER_TYPE_NC = "CO_W_RENOMMER_TYPE_NC";
export const CO_W_RENSEIGNEMENTS = "CO_W_RENSEIGNEMENTS";
export const CO_W_REPERTOIRE_POSTES = "CO_W_REPERTOIRE_POSTES";
export const CO_W_REPERTOIRE_TACHES = "CO_W_REPERTOIRE_TACHES";
export const CO_W_RESPONSABILITE = "CO_W_RESPONSABILITE";
export const CO_W_SIGNALEMENT = "CO_W_SIGNALEMENT";
export const CO_W_SIGNALEMENT_SIMPLIFIER = "CO_W_SIGNALEMENT_SIMPLIFIER";
export const CO_W_STATUT_ACCIDENT = "CO_W_STATUT_ACCIDENT";
export const CO_W_TRAITEMENT_DEMANDE = "CO_W_TRAITEMENT_DEMANDE";
export const CO_W_TYPE_ASSIGNATION = "CO_W_TYPE_ASSIGNATION";
export const CO_W_TYPE_BLESSURE = "CO_W_TYPE_BLESSURE";
export const CO_W_TYPE_CAUSE_FONDAM = "CO_W_TYPE_CAUSE_FONDAM";
export const CO_W_TYPE_DEMANDE = "CO_W_TYPE_DEMANDE";
export const CO_W_TYPE_GRAVITE = "CO_W_TYPE_GRAVITE";
export const CO_W_TYPE_GRAVITE_POTENTIEL = "CO_W_TYPE_GRAVITE_POTENTIEL";
export const CO_W_TYPE_PREMIER_SOIN = "CO_W_TYPE_PREMIER_SOIN";
export const CO_W_TYPE_PRODUIT_DEVERSE = "CO_W_TYPE_PRODUIT_DEVERSE";
export const CO_W_TYPE_RESTRICTION = "CO_W_TYPE_RESTRICTION";
export const CO_W_TYPES_AUDITS = "CO_W_TYPES_AUDITS";
export const CO_W_UNITE_MESURE = "CO_W_UNITE_MESURE";
export const CO_W_URGENCE = "CO_W_URGENCE";
export const CO_W_VARIABLE_SYSTEME = "CO_W_VARIABLE_SYSTEME";
export const CO_WP_ARRET_TRAVAIL = "CO_WP_ARRET_TRAVAIL";
export const CO_WP_ASSIGNATIONS = "CO_WP_ASSIGNATIONS";
export const CO_WP_COMMENTAIRES = "CO_WP_COMMENTAIRES";
export const CO_WP_COUT = "CO_WP_COUT";
export const CO_WP_DOMMAGES = "CO_WP_DOMMAGES";
export const CO_WP_FORMULE_BENCH = "CO_WP_FORMULE_BENCH";
export const CO_WP_PARAMETRE_DETAIL = "CO_WP_PARAMETRE_DETAIL";
export const CO_WP_PREMIERS_SOINS = "CO_WP_PREMIERS_SOINS";
export const CO_WP_SUIVI_MEDICAL = "CO_WP_SUIVI_MEDICAL";
export const CO_WR_INDICATEURS_PERFORMANCE = "CO_WR_INDICATEURS_PERFORMANCE";
export const CO_WRP_ACT_SURVEIL_FORMULAIRE_VIERGE =
    "CO_WRP_ACT_SURVEIL_FORMULAIRE_VIERGE";
export const CO_WRP_ACTIONS_CORRECT_PREVENT = "CO_WRP_ACTIONS_CORRECT_PREVENT";
export const CO_WRP_ACTIVITE_SURVEILLANCE = "CO_WRP_ACTIVITE_SURVEILLANCE";
export const CO_WRP_ARRET_ASSIGNATION = "CO_WRP_ARRET_ASSIGNATION";
export const CO_WRP_AUDIT_PLAN = "CO_WRP_AUDIT_PLAN";
export const CO_WRP_AUDIT_STAT = "CO_WRP_AUDIT_STAT";
export const CO_WRP_AUDIT_STAT_PERSONNES = "CO_WRP_AUDIT_STAT_PERSONNES";
export const CO_WRP_AUDIT_STAT_QUESTION = "CO_WRP_AUDIT_STAT_QUESTION";
export const CO_WRP_AVIS_INFRACTION_FR = "CO_WRP_AVIS_INFRACTION_FR";
export const CO_WRP_CALENDRIER_LISTE_AUDITS = "CO_WRP_CALENDRIER_LISTE_AUDITS";
export const CO_WRP_CERTIFICAT_ANALYSE = "CO_WRP_CERTIFICAT_ANALYSE";
export const CO_WRP_COMDEV_JLM = "CO_WRP_COMDEV_JLM";
export const CO_WRP_DEROGATION = "CO_WRP_DEROGATION";
export const CO_WRP_DEROGATION_JLM = "CO_WRP_DEROGATION_JLM";
export const CO_WRP_DEVERSEMENT_GOLDCORP = "CO_WRP_DEVERSEMENT_GOLDCORP";
export const CO_WRP_ECHANTILLON_RESULTAT = "CO_WRP_ECHANTILLON_RESULTAT";
export const CO_WRP_FEUILLET_PELICAN = "CO_WRP_FEUILLET_PELICAN";
export const CO_WRP_FICHE_ECART_NC_MRN = "CO_WRP_FICHE_ECART_NC_MRN";
export const CO_WRP_GRILLE_SOMMAIRE = "CO_WRP_GRILLE_SOMMAIRE";
export const CO_WRP_HISTOGRAMME_NON_CONFORMITE =
    "CO_WRP_HISTOGRAMME_NON_CONFORMITE";
export const CO_WRP_NON_CONFORMITE_8D_INTERNE =
    "CO_WRP_NON_CONFORMITE_8D_INTERNE";
export const CO_WRP_NON_CONFORMITE_COH = "CO_WRP_NON_CONFORMITE_COH";
export const CO_WRP_NON_CONFORMITE_JLM = "CO_WRP_NON_CONFORMITE_JLM";
export const CO_WRP_PARETO_NON_CONFORMITE = "CO_WRP_PARETO_NON_CONFORMITE";
export const CO_WRP_QUESTIONNAIRE_AUDIT = "CO_WRP_QUESTIONNAIRE_AUDIT";
export const CO_WRP_QUESTIONNAIRE_AUDIT_PAR_PERSONNE =
    "CO_WRP_QUESTIONNAIRE_AUDIT_PAR_PERSONNE";
export const CO_WRP_RAPPORT_AUDIT = "CO_WRP_RAPPORT_AUDIT";
export const CO_WRP_RAPPORT_AUDIT_MRNF = "CO_WRP_RAPPORT_AUDIT_MRNF";
export const CO_WRP_RAPPORT_DEMANDE_MRN = "CO_WRP_RAPPORT_DEMANDE_MRN";
export const CO_WRP_RAPPORT_INSPECTION = "CO_WRP_RAPPORT_INSPECTION";
export const CO_WRP_RAPPORT_PELICAN = "CO_WRP_RAPPORT_PELICAN";
export const CO_WRP_RAPPORT_TARKETT = "CO_WRP_RAPPORT_TARKETT";
export const CO_WRP_REGISTRE_DEMANDE_MRN = "CO_WRP_REGISTRE_DEMANDE_MRN";
export const CO_WRP_REGISTRE_ECART_NC_MRN = "CO_WRP_REGISTRE_ECART_NC_MRN";
export const CO_WRP_RENSEIGNEMENTS = "CO_WRP_RENSEIGNEMENTS";
export const CO_WRP_RENSEIGNEMENTS_JLM = "CO_WRP_RENSEIGNEMENTS_JLM";
export const CO_WRP_RESULTAT_ECH_PARAM = "CO_WRP_RESULTAT_ECH_PARAM";
export const CO_WRP_RESULTAT_ECHANTILLONNAGE =
    "CO_WRP_RESULTAT_ECHANTILLONNAGE";
export const CO_WRP_STATISTIQUE_ACCIDENT = "CO_WRP_STATISTIQUE_ACCIDENT";
export const CO_WRP_SUIVI_NON_CONFORMITE = "CO_WRP_SUIVI_NON_CONFORMITE";
export const CO_WRP_SUIVI_PARAMETRE = "CO_WRP_SUIVI_PARAMETRE";
export const CO_WRP_TABLEAU_SOMMAIRE = "CO_WRP_TABLEAU_SOMMAIRE";
export const DO_OBJET_DOCUMENT_1 = "DO_OBJET_DOCUMENT_1";
export const DO_OBJET_DOCUMENT_1_RECHERCHE = "DO_OBJET_DOCUMENT_1_RECHERCHE";
export const DO_OBJET_DOCUMENT_2 = "DO_OBJET_DOCUMENT_2";
export const DO_OBJET_DOCUMENT_2_RECHERCHE = "DO_OBJET_DOCUMENT_2_RECHERCHE";
export const DO_OBJET_DOCUMENT_3 = "DO_OBJET_DOCUMENT_3";
export const DO_OBJET_DOCUMENT_3_RECHERCHE = "DO_OBJET_DOCUMENT_3_RECHERCHE";
export const DO_OBJET_DOCUMENT_SIMPLIFIE = "DO_OBJET_DOCUMENT_SIMPLIFIE";
export const DO_OBJET_DOCUMENT_SIMPLIFIE_2 = "DO_OBJET_DOCUMENT_SIMPLIFIE_2";
export const DO_OBJET_DOSSIER_1 = "DO_OBJET_DOSSIER_1";
export const DO_OBJET_DOSSIER_1_RECHERCHE = "DO_OBJET_DOSSIER_1_RECHERCHE";
export const DO_OBJET_DOSSIER_2 = "DO_OBJET_DOSSIER_2";
export const DO_OBJET_DOSSIER_2_RECHERCHE = "DO_OBJET_DOSSIER_2_RECHERCHE";
export const DO_OBJET_DOSSIER_SIMPLIFIE = "DO_OBJET_DOSSIER_SIMPLIFIE";
export const DO_OBJET_FAMILLE_ACTIVITES = "DO_OBJET_FAMILLE_ACTIVITES";
export const DO_OBJET_FAMILLE_ACTIVITES_SIMPLIFIE =
    "DO_OBJET_FAMILLE_ACTIVITES_SIMPLIFIE";
export const DO_W_CAL_CONSERV_CODE_EXCEPTION =
    "DO_W_CAL_CONSERV_CODE_EXCEPTION";
export const DO_W_CAL_CONSERV_CODE_OPERATION =
    "DO_W_CAL_CONSERV_CODE_OPERATION";
export const DO_W_CALENDRIER_CONSERVATION = "DO_W_CALENDRIER_CONSERVATION";
export const DO_W_CATEGORIES_MOTS_CLES = "DO_W_CATEGORIES_MOTS_CLES";
export const DO_W_CONFIG_MASSIVE_LISTE_CONTROLE =
    "DO_W_CONFIG_MASSIVE_LISTE_CONTROLE";
export const DO_W_CONFIG_MODULE = "DO_W_CONFIG_MODULE";    
export const DO_W_CONFIG_MODULE_GED = "DO_W_CONFIG_MODULE_GED";
export const DO_W_CONFIG_MODULE_GEC = "DO_W_CONFIG_MODULE_GEC";
export const DO_W_CONSULTATION_MASSE_DOCUMENTAIRE =
    "DO_W_CONSULTATION_MASSE_DOCUMENTAIRE";
export const DO_W_CONVERSION_DOCUMENT_PDF = "DO_W_CONVERSION_DOCUMENT_PDF";
export const DO_W_EXEMPLAIRE_SEC = "DO_W_EXEMPLAIRE_SEC";
export const DO_W_EXEMPLAIRE_SEC_2 = "DO_W_EXEMPLAIRE_SEC_2";
export const DO_W_FORMULAIRE = "DO_W_FORMULAIRE";
export const DO_W_GESTION_DOCUMENTAIRE = "DO_W_GESTION_DOCUMENTAIRE";
export const DO_W_GESTION_ORIGINAUX = "DO_W_GESTION_ORIGINAUX";
export const DO_W_IMPORTATION_MASSIVE_DOCUMENT =
    "DO_W_IMPORTATION_MASSIVE_DOCUMENT";
export const DO_W_LOCALISATION = "DO_W_LOCALISATION";
export const DO_W_LOGICIELS_DISPONIBLES = "DO_W_LOGICIELS_DISPONIBLES";
export const DO_W_MEMBRE_GROUPE_DIFFUSION = "DO_W_MEMBRE_GROUPE_DIFFUSION";
export const DO_W_MODIFIER_AUTEUR = "DO_W_MODIFIER_AUTEUR";
export const DO_W_MODIFIER_LOCALISATION = "DO_W_MODIFIER_LOCALISATION";
export const DO_W_MODIFIER_RESPONSABLE = "DO_W_MODIFIER_RESPONSABLE";
export const DO_W_MODIFIER_TYPE_DOCUMENT = "DO_W_MODIFIER_TYPE_DOCUMENT";
export const DO_W_REACTIVER_DOS_DOC = "DO_W_REACTIVER_DOS_DOC";
export const DO_W_REGISTRE_PRET = "DO_W_REGISTRE_PRET";
export const DO_W_SECTION_CAL_CONSERV = "DO_W_SECTION_CAL_CONSERV";
export const DO_W_SUPPORT_ARCHIVE = "DO_W_SUPPORT_ARCHIVE";
export const DO_W_TRAITEMENT_DEMANDE = "DO_W_TRAITEMENT_DEMANDE";
export const DO_W_TRAITEMENT_HORODATAGE = "DO_W_TRAITEMENT_HORODATAGE";
export const DO_W_TYPE_DEMANDE = "DO_W_TYPE_DEMANDE";
export const DO_W_MODELE_DOCUMENT = "DO_W_MODELE_DOCUMENT";
export const DO_W_TYPE_DOCUMENT = "DO_W_TYPE_DOCUMENT";
export const DO_W_TYPE_DOSSIER = "DO_W_TYPE_DOSSIER";
export const DO_W_TYPE_DOSSIER_DETAIL = "DO_W_TYPE_DOSSIER_DETAIL";
export const DO_W_TYPE_LISTE_CONTROLE = "DO_W_TYPE_LISTE_CONTROLE";
export const DO_W_TYPE_SUPPORT = "DO_W_TYPE_SUPPORT";
export const DO_W_UNITE_ADM = "DO_W_UNITE_ADM";
export const DO_WP_CONFIGURATION_LISTE_CONTROLE =
    "DO_WP_CONFIGURATION_LISTE_CONTROLE";
export const DO_WP_RECHERCHE_DOCUMENT = "DO_WP_RECHERCHE_DOCUMENT";
export const DO_WP_RECHERCHE_DOSSIER = "DO_WP_RECHERCHE_DOSSIER";
export const DO_WR_CAL_CONSERV_LISTE_MODIFICATIONS =
    "DO_WR_CAL_CONSERV_LISTE_MODIFICATIONS";
export const DO_WR_CALENDRIER_CONSERVATION_INDEX =
    "DO_WR_CALENDRIER_CONSERVATION_INDEX";
export const DO_WR_COMPETENCES_DOCUMENTS_CRITERE =
    "DO_WR_COMPETENCES_DOCUMENTS_CRITERE";
export const DO_WR_DECLASSEMENT = "DO_WR_DECLASSEMENT";
export const DO_WR_INDICATEURS_PERFORMANCE = "DO_WR_INDICATEURS_PERFORMANCE";
export const DO_WR_LISTE_DEMANDES = "DO_WR_LISTE_DEMANDES";
export const DO_WR_PLAN_CLASSIFICATION = "DO_WR_PLAN_CLASSIFICATION";
export const DO_WR_PLAN_CLASSIFICATION_INDEX =
    "DO_WR_PLAN_CLASSIFICATION_INDEX";
export const DO_WR_REGISTRE_PRET = "DO_WR_REGISTRE_PRET";
export const DO_WRP_CALENDRIER_CONSERVATION = "DO_WRP_CALENDRIER_CONSERVATION";
export const DO_WRP_CONFIGURATION_LISTE_CONTROLE =
    "DO_WRP_CONFIGURATION_LISTE_CONTROLE";
export const DO_WRP_DEMANDE_APPROBATION_DEPENSE =
    "DO_WRP_DEMANDE_APPROBATION_DEPENSE";
export const DO_WRP_DOS_DOC_DETRUITS = "DO_WRP_DOS_DOC_DETRUITS";
export const DO_WRP_EXEMMPLAIRE_SECONDAIRE_INVENT =
    "DO_WRP_EXEMMPLAIRE_SECONDAIRE_INVENT";
export const DO_WRP_LISTE_DOC_PAR_TYPE = "DO_WRP_LISTE_DOC_PAR_TYPE";
export const DO_WRP_MEMBRE_GROUPE_GED = "DO_WRP_MEMBRE_GROUPE_GED";
export const DO_WRP_SUPPORT_ARCHIVE = "DO_WRP_SUPPORT_ARCHIVE";
export const DO_WRP_HISTO_CONSULT= "DO_WRP_HISTO_CONSULT";
export const EC_W_REGLE = "EC_W_REGLE";
export const EM_WP_EQUIPEMENT_CRITERE = "EM_WP_EQUIPEMENT_CRITERE";
export const EQ_W_CERTIFICATIONS = "EQ_W_CERTIFICATIONS";
export const EQ_W_CONFIG_MODULE_EQUIPEMENT = "EQ_W_CONFIG_MODULE_EQUIPEMENT";
export const EQ_W_EQUIPEMENTS_MESURE = "EQ_W_EQUIPEMENTS_MESURE";
export const EQ_W_GESTION_EQUIP = "EQ_W_GESTION_EQUIP";
export const EQ_W_HISTORIQUE = "EQ_W_HISTORIQUE";
export const EQ_W_LOCALISATIONS_EQUIPEMENTS = "EQ_W_LOCALISATIONS_EQUIPEMENTS";
export const EQ_W_RESULTATS_ETALONNAGE = "EQ_W_RESULTATS_ETALONNAGE";
export const EQ_W_RESULTATS_ETALONNAGE_DETAIL =
    "EQ_W_RESULTATS_ETALONNAGE_DETAIL";
export const EQ_W_SAISIE_CUMUL_UTILISATION = "EQ_W_SAISIE_CUMUL_UTILISATION";
export const EQ_W_SAISIE_EVENEMENT = "EQ_W_SAISIE_EVENEMENT";
export const EQ_W_SAISIE_STATUT_CALENDRIER = "EQ_W_SAISIE_STATUT_CALENDRIER";
export const EQ_W_SAISIE_UTILISATION_EQUIPEMENT =
    "EQ_W_SAISIE_UTILISATION_EQUIPEMENT";
export const EQ_W_SAISIE_VERIFICATION = "EQ_W_SAISIE_VERIFICATION";
export const EQ_W_TYPES_ACTIVITES_ENTRETIEN = "EQ_W_TYPES_ACTIVITES_ENTRETIEN";
export const EQ_W_TYPES_COMPETENCES = "EQ_W_TYPES_COMPETENCES";
export const EQ_W_TYPES_EQUIPEMENTS = "EQ_W_TYPES_EQUIPEMENTS";
export const EQ_W_TYPES_EVENEMENTS = "EQ_W_TYPES_EVENEMENTS";
export const EQ_W_TYPES_STATUT_EQUIPEMENTS = "EQ_W_TYPES_STATUT_EQUIPEMENTS";
export const EQ_WR_INDICATEURS_PERFORMANCE = "EQ_WR_INDICATEURS_PERFORMANCE";
export const EQ_WRP_CALENDRIER_ACTIVITES_ENTRETIEN =
    "EQ_WRP_CALENDRIER_ACTIVITES_ENTRETIEN";
export const EQ_WRP_FICHE_EQUIPEMENT = "EQ_WRP_FICHE_EQUIPEMENT";
export const EQ_WRP_HISTORIQUE_EQUIPEMENT = "EQ_WRP_HISTORIQUE_EQUIPEMENT";
export const EQ_WRP_PRETS_EQUIPEMENTS = "EQ_WRP_PRETS_EQUIPEMENTS";
export const EQ_WRP_STATUT_LOCALISATION = "EQ_WRP_STATUT_LOCALISATION";
export const FC_W_COACHING = "FC_W_COACHING";
export const FC_W_COMPETENCES_EMPLOYES_POSTES =
    "FC_W_COMPETENCES_EMPLOYES_POSTES";
export const FC_W_COMPETENCES_FUSIONNER = "FC_W_COMPETENCES_FUSIONNER";
export const FC_W_CONFIG_MODULE_FC = "FC_W_CONFIG_MODULE_FC";
export const FC_W_DEMANDES_CONFIRMATION = "FC_W_DEMANDES_CONFIRMATION";
export const FC_W_EMPLOYE = "FC_W_EMPLOYE";
export const FC_W_EQUIPEMENT_PROTECTION = "FC_W_EQUIPEMENT_PROTECTION";
export const FC_W_FORMATIONS_COUTS_EMPLOYES_PRESENCE =
    "FC_W_FORMATIONS_COUTS_EMPLOYES_PRESENCE";
export const FC_W_MAJ_COMPETENCES_WEB_SEULEMENT =
    "FC_W_MAJ_COMPETENCES_WEB_SEULEMENT";
export const FC_W_MOYENS_SQDM = "FC_W_MOYENS_SQDM";
export const FC_W_NIVEAU_MAITRISE = "FC_W_NIVEAU_MAITRISE";
export const FC_W_POSTE_PREREQUIS_REQUERANT = "FC_W_POSTE_PREREQUIS_REQUERANT";
export const FC_W_POSTES_COMPETENCES_EMPLOYES =
    "FC_W_POSTES_COMPETENCES_EMPLOYES";
export const FC_W_PROTECT_IMPORTATION_POSTE = "FC_W_PROTECT_IMPORTATION_POSTE";
export const FC_W_PROTECTION_IMPORTATION = "FC_W_PROTECTION_IMPORTATION";
export const FC_W_RENS_IMPORTATION_POSTE = "FC_W_RENS_IMPORTATION_POSTE";
export const FC_W_RENS_IMPORTATION_POSTE_OCCUPE =
    "FC_W_RENS_IMPORTATION_POSTE_OCCUPE";
export const FC_W_RENSEIGNEMENT_IMPORTATION = "FC_W_RENSEIGNEMENT_IMPORTATION";
export const FC_W_SAISIE_RAPIDE_CATEGORIE = "FC_W_SAISIE_RAPIDE_CATEGORIE";
export const FC_W_SAISIE_RAPIDE_SALAIRES = "FC_W_SAISIE_RAPIDE_SALAIRES";
export const FC_WR_ATTESTATION_FORMATION_CRITERE =
    "FC_WR_ATTESTATION_FORMATION_CRITERE";
export const FC_WR_COACHING = "FC_WR_COACHING";
export const FC_WR_COACHING_LISTE_COACHING = "FC_WR_COACHING_LISTE_COACHING";
export const FC_WR_COMPETENCES_MANQ_RENOUV_CRITERE =
    "FC_WR_COMPETENCES_MANQ_RENOUV_CRITERE";
export const FC_WR_COMPETENCES_RECONNUES_CRITERE =
    "FC_WR_COMPETENCES_RECONNUES_CRITERE";
export const FC_WR_COMPETENCES_REQUISES_CRITERE =
    "FC_WR_COMPETENCES_REQUISES_CRITERE";
export const FC_WR_COMPETENCES_RETARD_CRITERE =
    "FC_WR_COMPETENCES_RETARD_CRITERE";
export const FC_WR_EMPLOYES_POSTESOCCUPES_CRITERE =
    "FC_WR_EMPLOYES_POSTESOCCUPES_CRITERE";
export const FC_WR_EVALUATION_PERFORMANCE_CRITERE =
    "FC_WR_EVALUATION_PERFORMANCE_CRITERE";
export const FC_WR_INDICATEURS_PERFORMANCE = "FC_WR_INDICATEURS_PERFORMANCE";
export const FC_WR_MATRICE_COMPETENCE = "FC_WR_MATRICE_COMPETENCE";
export const FC_WR_MATRICE_POSTE = "FC_WR_MATRICE_POSTE";
export const FC_WR_RAPPEL_FORMATIONS_CRITERE =
    "FC_WR_RAPPEL_FORMATIONS_CRITERE";
export const FC_WR_REAL_ANNU_COUT_TOTAL_FORM_CRIT =
    "FC_WR_REAL_ANNU_COUT_TOTAL_FORM_CRIT";
export const FC_WRP_ANALYSE_COUTS_FORMATION_DETAIL =
    "FC_WRP_ANALYSE_COUTS_FORMATION_DETAIL";
export const FC_WRP_ATTESTATION_FORMATION_LUSSIER =
    "FC_WRP_ATTESTATION_FORMATION_LUSSIER";
export const FC_WRP_CALENDRIER_ACT_FORMATIONS_DETAIL =
    "FC_WRP_CALENDRIER_ACT_FORMATIONS_DETAIL";
export const FC_WRP_DOSSIER_EMPLOYE = "FC_WRP_DOSSIER_EMPLOYE";
export const FC_WRP_ETAT_COMPETENCE = "FC_WRP_ETAT_COMPETENCE";
export const FC_WRP_FEUILLE_PRESENCE_VIERGE_DETAIL =
    "FC_WRP_FEUILLE_PRESENCE_VIERGE_DETAIL";
export const FC_WRP_FEUILLE_PRESENCE_VIERGE_LUSSIER =
    "FC_WRP_FEUILLE_PRESENCE_VIERGE_LUSSIER";
export const FC_WRP_FORMATION_INDIVIDUELLE = "FC_WRP_FORMATION_INDIVIDUELLE";
export const FC_WRP_GRILLE_COUT_TOTAL_DETAIL =
    "FC_WRP_GRILLE_COUT_TOTAL_DETAIL";
export const FC_WRP_PLAN_FORMATION_DETAIL = "FC_WRP_PLAN_FORMATION_DETAIL";
export const FC_WRP_PRESENCES_FORMATION_DETAIL1 =
    "FC_WRP_PRESENCES_FORMATION_DETAIL1";
export const FC_WRP_REGISTRE_PRESENCE_DETAIL =
    "FC_WRP_REGISTRE_PRESENCE_DETAIL";
export const FL_W_FAIRE_PASSER_FORMATION = "FL_W_FAIRE_PASSER_FORMATION";
export const FL_W_FORMATIONS_EMPLOYES_PRESENCE =
    "FL_W_FORMATIONS_EMPLOYES_PRESENCE";
export const FL_W_FORMATIONS_INSCRIPTION = "FL_W_FORMATIONS_INSCRIPTION";
export const FL_W_RECEVOIR_FORMATION = "FL_W_RECEVOIR_FORMATION";
export const FO_W_CERTIFICAT_ORIGINE_REQUIS = "FO_W_CERTIFICAT_ORIGINE_REQUIS";
export const FO_W_CERTIFICAT_SYSTEM_QUALITE = "FO_W_CERTIFICAT_SYSTEM_QUALITE";
export const FO_W_CONFIG_MODULE_FOURN = "FO_W_CONFIG_MODULE_FOURN";
export const FO_W_CONTROLE_ENTREE = "FO_W_CONTROLE_ENTREE";
export const FO_W_CONTROLE_PRODUIT_N_C = "FO_W_CONTROLE_PRODUIT_N_C";
export const FO_W_DEVISES = "FO_W_DEVISES";
export const FO_W_DIMENSION_CONTAINER = "FO_W_DIMENSION_CONTAINER";
export const FO_W_DOCUMENT_REMPLIR = "FO_W_DOCUMENT_REMPLIR";
export const FO_W_FOURN_CATEGORIE = "FO_W_FOURN_CATEGORIE";
export const FO_W_FOURN_CLASSEMENT = "FO_W_FOURN_CLASSEMENT";
export const FO_W_FOURN_COUT_NET = "FO_W_FOURN_COUT_NET";
export const FO_W_FOURN_CRITERE_PREFERENCE = "FO_W_FOURN_CRITERE_PREFERENCE";
export const FO_W_FOURN_FREQ_INSPECTION = "FO_W_FOURN_FREQ_INSPECTION";
export const FO_W_FOURN_NIVEAU_RISK = "FO_W_FOURN_NIVEAU_RISK";
export const FO_W_FOURN_PRODUCTEUR = "FO_W_FOURN_PRODUCTEUR";
export const FO_W_FOURN_STATUT = "FO_W_FOURN_STATUT";
export const FO_W_FOURNISSEUR = "FO_W_FOURNISSEUR";
export const FO_W_GESTION_PRODUIT = "FO_W_GESTION_PRODUIT";
export const FO_W_INCOTERM = "FO_W_INCOTERM";
export const FO_W_MAINTENANCE_DIRECTIVE_EMBALLAGE =
    "FO_W_MAINTENANCE_DIRECTIVE_EMBALLAGE";
export const FO_W_MAINTENANCE_FAMILLE_PRODUIT =
    "FO_W_MAINTENANCE_FAMILLE_PRODUIT";
export const FO_W_MAINTENANCE_LIGNE_PRODUIT = "FO_W_MAINTENANCE_LIGNE_PRODUIT";
export const FO_W_MODEL_PROCESSUS_QUALIF = "FO_W_MODEL_PROCESSUS_QUALIF";
export const FO_W_MODEL_REQ_PROCES_QUALIF = "FO_W_MODEL_REQ_PROCES_QUALIF";
export const FO_W_PROCESSUS_CERTIFICAT_ORIGINE =
    "FO_W_PROCESSUS_CERTIFICAT_ORIGINE";
export const FO_W_PROTECT_IMPORTATION_PSF = "FO_W_PROTECT_IMPORTATION_PSF";
export const FO_W_PROTECTION_IMPORTATION = "FO_W_PROTECTION_IMPORTATION";
export const FO_W_QUEST_POSS_PROCESS_QUALIF = "FO_W_QUEST_POSS_PROCESS_QUALIF";
export const FO_W_QUEST_REQUETE_POSSIBLE = "FO_W_QUEST_REQUETE_POSSIBLE";
export const FO_W_QUEST_REQUETE_REPON_POSS = "FO_W_QUEST_REQUETE_REPON_POSS";
export const FO_W_RENS_IMPORTATION_PSF = "FO_W_RENS_IMPORTATION_PSF";
export const FO_W_RENSEIGNEMENT_IMPORTATION = "FO_W_RENSEIGNEMENT_IMPORTATION";
export const FO_W_REQUETE_QUALIF = "FO_W_REQUETE_QUALIF";
export const FO_W_TERME_DEDOUANEMENT = "FO_W_TERME_DEDOUANEMENT";
export const FO_W_TYPE_DEDUCTION_APPLICABLE = "FO_W_TYPE_DEDUCTION_APPLICABLE";
export const FO_W_TYPE_EVALUATION = "FO_W_TYPE_EVALUATION";
export const FO_W_TYPE_PRODUIT = "FO_W_TYPE_PRODUIT";
export const FO_WP_ACTION = "FO_WP_ACTION";
export const FO_WP_AUDIT = "FO_WP_AUDIT";
export const FO_WP_CIBLE = "FO_WP_CIBLE";
export const FO_WP_COMPETENCE = "FO_WP_COMPETENCE";
export const FO_WP_EMPLOYE = "FO_WP_EMPLOYE";
export const FO_WP_EQUIPEMENT = "FO_WP_EQUIPEMENT";
export const FO_WP_EVALUATION = "FO_WP_EVALUATION";
export const FO_WP_FORMATION = "FO_WP_FORMATION";
export const FO_WP_GRAPHIQUE = "FO_WP_GRAPHIQUE";
export const FO_WP_NC_OPP = "FO_WP_NC_OPP";
export const FO_WP_OBJECTIF = "FO_WP_OBJECTIF";
export const FO_WP_PROJET = "FO_WP_PROJET";
export const FO_WP_REUNION = "FO_WP_REUNION";
export const FO_WP_SURVEILLANCE = "FO_WP_SURVEILLANCE";
export const FO_WRP_AFTERMARKET = "FO_WRP_AFTERMARKET";
export const FO_WRP_DIRECTIVE_EMBALLAGE = "FO_WRP_DIRECTIVE_EMBALLAGE";
export const FO_WRP_DOSSIER = "FO_WRP_DOSSIER";
export const FO_WRP_HISTORIQUE_ACCES = "FO_WRP_HISTORIQUE_ACCES";
export const FO_WRP_REQUETE_QUALIF = "FO_WRP_REQUETE_QUALIF";
export const FO_WRP_SOMMAIRE_AUTORISATION = "FO_WRP_SOMMAIRE_AUTORISATION";
export const GE_W_ACTION_A_FAIRE = "GE_W_ACTION_A_FAIRE";
export const GE_W_ASSIGN_ARRET_COURS = "GE_W_ASSIGN_ARRET_COURS";
export const GE_W_BABILLARD = "GE_W_BABILLARD";
export const GE_W_BABILLARD_DATES_CRITERES = "GE_W_BABILLARD_DATES_CRITERES";
export const GE_W_CALENDRIER_ACTIVITES = "GE_W_CALENDRIER_ACTIVITES";
export const GE_W_CHANGER_SUPERIEUR = "GE_W_CHANGER_SUPERIEUR";
export const GE_W_COMMUNICATION = "GE_W_COMMUNICATION";
export const GE_W_DEMANDES_TRAITER = "GE_W_DEMANDES_TRAITER";
export const GE_W_DEROGATION_COURS = "GE_W_DEROGATION_COURS";
export const GE_W_DUPLIQUER_ACCES = "GE_W_DUPLIQUER_ACCES";
export const GE_W_ELEM_A_REVISER_COURS = "GE_W_ELEM_A_REVISER_COURS";
export const GE_W_ETAT_SERVICE = "GE_W_ETAT_SERVICE";
export const GE_W_GESTION_CSV = "GE_W_GESTION_CSV";
export const GE_W_GESTION_SITE = "GE_W_GESTION_SITE";
export const GE_W_HISTORIQUE_BACKUP = "GE_W_HISTORIQUE_BACKUP";
export const GE_W_MEMBRE_GROUPE_ISOVISION = "GE_W_MEMBRE_GROUPE_ISOVISION";
export const GE_W_MEMBRE_LISTE_DISTRIBUTION = "GE_W_MEMBRE_LISTE_DISTRIBUTION";
export const GE_W_NON_CONF_COURS = "GE_W_NON_CONF_COURS";
export const GE_W_OPPORTUNITE_COURS = "GE_W_OPPORTUNITE_COURS";
export const GE_W_PRIORITE_DOCUMENT_PDF = "GE_W_PRIORITE_DOCUMENT_PDF";
export const GE_W_PROJETS = "GE_W_PROJETS";
export const GE_W_RAPPEL_AUTO = "GE_W_RAPPEL_AUTO";
export const GE_W_REMPLACER_PERSONNE = "GE_W_REMPLACER_PERSONNE";
export const GE_W_RENOMMER_MODULE = "GE_W_RENOMMER_MODULE";
export const GE_W_RENSEIGNEMENTS_COURS = "GE_W_RENSEIGNEMENTS_COURS";
export const GE_W_SOMMAIRE = "GE_W_SOMMAIRE";
export const GE_W_TABLEAU_BORD = "GE_W_TABLEAU_BORD";
export const GE_W_TABLEAU_BORD_FILTRE = "GE_W_TABLEAU_BORD_FILTRE";
export const GE_W_TABLEAU_BORD_SETUP = "GE_W_TABLEAU_BORD_SETUP";
export const GE_W_TACHE_NIGHTWORKER = "GE_W_TACHE_NIGHTWORKER";
export const GE_W_TRAITEMENT_IVREMIND = "GE_W_TRAITEMENT_IVREMIND";
export const GE_W_VERIF_EFFICACITE = "GE_W_VERIF_EFFICACITE";
export const GE_WP_BABILLARD_EDIT = "GE_WP_BABILLARD_EDIT";
export const GE_WP_CALENDRIER_ACTIVITES_DETAILS =
    "GE_WP_CALENDRIER_ACTIVITES_DETAILS";
export const GE_WP_DEROGATION_COURS_DETAILS = "GE_WP_DEROGATION_COURS_DETAILS";
export const GE_WP_ELEM_A_REVISER_COURS_DETAILS =
    "GE_WP_ELEM_A_REVISER_COURS_DETAILS";
export const GE_WP_NON_CONF_COURS_DETAILS = "GE_WP_NON_CONF_COURS_DETAILS";
export const GE_WP_PROJETS_DETAILS = "GE_WP_PROJETS_DETAILS";
export const GE_WP_RENSEIGNEMENTS_COURS_DETAILS =
    "GE_WP_RENSEIGNEMENTS_COURS_DETAILS";
export const GE_WP_VERIF_EFFICACITE_DETAILS = "GE_WP_VERIF_EFFICACITE_DETAILS";
export const GE_WRP_CALENDRIER_ACTION = "GE_WRP_CALENDRIER_ACTION";
export const GE_WRP_CALENDRIER_ACTIVITES = "GE_WRP_CALENDRIER_ACTIVITES";
export const GE_WRP_ELEMENTS_REVISER = "GE_WRP_ELEMENTS_REVISER";
export const GE_WRP_N_C_RAPPORT_MENSUEL = "GE_WRP_N_C_RAPPORT_MENSUEL";
export const ISO_SEC_W_ARBO_ACCES = "ISO_SEC_W_ARBO_ACCES";
export const ISO_SEC_W_POL_LOG = "ISO_SEC_W_POL_LOG";
export const ISO_SEC_W_POL_LOGIN = "ISO_SEC_W_POL_LOGIN";
export const ISO_SEC_W_POL_MOTPASSE = "ISO_SEC_W_POL_MOTPASSE";
export const ISO_SEC_WRP_CONFIG_USAGERS = "ISO_SEC_WRP_CONFIG_USAGERS";
export const ISO_SEC_WRP_GESTION_ACCES = "ISO_SEC_WRP_GESTION_ACCES";
export const ISO_SEC_WRP_MEMBRE_GROUPE = "ISO_SEC_WRP_MEMBRE_GROUPE";
export const ISO_W_TRADUCTION_TABLE = "ISO_W_TRADUCTION_TABLE";
export const MO_W_ACTIVITE_AD_HOC = "MO_W_ACTIVITE_AD_HOC";
export const MO_W_CARTE_ACCES = "MO_W_CARTE_ACCES";
export const MO_W_CATEGORIE_CONTACT = "MO_W_CATEGORIE_CONTACT";
export const MO_W_CATEGORIE_EMPLOYE = "MO_W_CATEGORIE_EMPLOYE";
export const MO_W_CREATION_RAPIDE_EMPLOYE = "MO_W_CREATION_RAPIDE_EMPLOYE";
export const MO_W_EMPLOYE = "MO_W_EMPLOYE";
export const MO_W_EMPLOYE_STATUT = "MO_W_EMPLOYE_STATUT";
export const MO_W_PARTIE_INTERESSE = "MO_W_PARTIE_INTERESSE";
export const MO_W_PROGR_COMMUNIC = "MO_W_PROGR_COMMUNIC";
export const MO_W_TYPE_PERSONNE = "MO_W_TYPE_PERSONNE";
export const MO_WRP_LISTE_ACT_ROUTINIERE_ADHOC =
    "MO_WRP_LISTE_ACT_ROUTINIERE_ADHOC";
export const MO_WRP_LISTE_EMPLOYE = "MO_WRP_LISTE_EMPLOYE";
export const MO_WRP_PROGRAM_DE_COMMUNICATION =
    "MO_WRP_PROGRAM_DE_COMMUNICATION";
export const PL_W_ACTION_PREVUE = "PL_W_ACTION_PREVUE";
export const PL_W_ANALYSE_IMPACT = "PL_W_ANALYSE_IMPACT";
export const PL_W_CIBLE_NORME = "PL_W_CIBLE_NORME";
export const PL_W_OBJECTIF = "PL_W_OBJECTIF";
export const PL_W_ORIGINE_CIBLE = "PL_W_ORIGINE_CIBLE";
export const PL_W_PRIORITE_PROJET = "PL_W_PRIORITE_PROJET";
export const PL_W_PROCEDE_ACTIVITE = "PL_W_PROCEDE_ACTIVITE";
export const PL_W_PROCEDE_ACTIVITE_SMALL = "PL_W_PROCEDE_ACTIVITE_SMALL";
export const PL_W_REFERENCE = "PL_W_REFERENCE";
export const PL_W_RENOMMER_ECRAN_MENU = "PL_W_RENOMMER_ECRAN_MENU";
export const PL_W_SAISIE_ACTIV_PREV_WEB_SEULEMENT = "PL_W_SAISIE_ACTIV_PREV_WEB_SEULEMENT";
export const PL_W_SAISIE_RAPIDE_SUIVI_ACTIONS =
    "PL_W_SAISIE_RAPIDE_SUIVI_ACTIONS";
export const PL_W_SAISIE_RAPIDE_SUIVI_CIBLE = "PL_W_SAISIE_RAPIDE_SUIVI_CIBLE";
export const PL_W_TYPE_RESSOURCE = "PL_W_TYPE_RESSOURCE";
export const PL_WR_INDICATEURS_PERFORMANCE = "PL_WR_INDICATEURS_PERFORMANCE";
export const PL_WRP_ACTIONS_PREVUES_DETAIL = "PL_WRP_ACTIONS_PREVUES_DETAIL";
export const PL_WRP_AVANCEMENT_PROJET = "PL_WRP_AVANCEMENT_PROJET";
export const PL_WRP_AVANCEMENT_PROJET_OBJECTIF =
    "PL_WRP_AVANCEMENT_PROJET_OBJECTIF";
export const PL_WRP_CIBLES_NORMES_DETAIL = "PL_WRP_CIBLES_NORMES_DETAIL";
export const PL_WRP_DIAGRAMME_GANTT = "PL_WRP_DIAGRAMME_GANTT";
export const PL_WRP_GRAPHIQUE_REALISE_PLANIFIE =
    "PL_WRP_GRAPHIQUE_REALISE_PLANIFIE";
export const PL_WRP_GRAPHIQUE_SOMMAIRE_PROJET =
    "PL_WRP_GRAPHIQUE_SOMMAIRE_PROJET";
export const PL_WRP_INTERRELATION_ACTION = "PL_WRP_INTERRELATION_ACTION";
export const PL_WRP_OBJECTIFS_CIBLES_ACTIONS_DETAIL =
    "PL_WRP_OBJECTIFS_CIBLES_ACTIONS_DETAIL";
export const PL_WRP_OBJECTIFS_DETAIL = "PL_WRP_OBJECTIFS_DETAIL";
export const PL_WRP_SUIVI_CIBLE_OBJECTIF = "PL_WRP_SUIVI_CIBLE_OBJECTIF";
export const PL_WRP_SUIVI_HEURE_REELLE = "PL_WRP_SUIVI_HEURE_REELLE";
export const PL_WRP_SUIVI_OBJECTIF = "PL_WRP_SUIVI_OBJECTIF";
export const PL_WRP_TAUX_OCCUPATION = "PL_WRP_TAUX_OCCUPATION";
export const RE_W_ACTION = "RE_W_ACTION";
export const RE_W_COMITE = "RE_W_COMITE";
export const RE_W_CONFIG_MODULE = "RE_W_CONFIG_MODULE";
export const RE_W_DECISION = "RE_W_DECISION";
export const RE_W_FONCTIONS = "RE_W_FONCTIONS";
export const RE_W_LOCALISATION = "RE_W_LOCALISATION";
export const RE_W_REUNION = "RE_W_REUNION";
export const RE_W_REUNION_CRITERE = "RE_W_REUNION_CRITERE";
export const RE_W_TRAITEMENT_DEMANDE = "RE_W_TRAITEMENT_DEMANDE";
export const RE_W_TYPE_DEMANDE = "RE_W_TYPE_DEMANDE";
export const RE_W_TYPES_REUNION = "RE_W_TYPES_REUNION";
export const RE_WR_INDICATEURS_PERFORMANCE_CRITERE =
    "RE_WR_INDICATEURS_PERFORMANCE_CRITERE";
export const RE_WR_LISTE_DEMANDES = "RE_WR_LISTE_DEMANDES";
export const RE_WRP_ACTION = "RE_WRP_ACTION";
export const RE_WRP_COMITE_COMPOSITION = "RE_WRP_COMITE_COMPOSITION";
export const RE_WRP_COMITE_PARTICIPATION = "RE_WRP_COMITE_PARTICIPATION";
export const RE_WRP_ORDRE_DU_JOUR_SEC = "RE_WRP_ORDRE_DU_JOUR_SEC";
export const SEC_W_ACCES = "SEC_W_ACCES";
export const SEC_W_APPLS_TYPES = "SEC_W_APPLS_TYPES";
export const SEC_W_ARBO = "SEC_W_ARBO";
export const SEC_W_CLASSES = "SEC_W_CLASSES";
export const SEC_W_USAGERS = "SEC_W_USAGERS";

export const DO_W_WEB_GESTION_CONNAISSANCE = "DO_W_WEB_GESTION_CONNAISSANCE";
export const DO_OBJET_UNIVERS = "DO_OBJET_UNIVERS";
export const DO_OBJET_CONNAISSANCE = "DO_OBJET_CONNAISSANCE";

//export const RE_W_ACTION_CRITERE = "RE_W_ACTION_CRITERE";
