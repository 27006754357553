/* 
=======================================================================
INSTRUCTIONS D'UTILISATION
=======================================================================

import { showSnackbarMessage } from 'store/slices/AlertMessageSlice'

Le composant <Snackbar/> a été appliqué au composant de niveau supérieur de l'application IsoVision (AppBootstrap)
et il n'est donc pas nécessaire de le rappeler dans les composants enfants.


- Paramètres obligatoires:
  {messageId: string || message: string, severity: string}
    
    - messageId: Id du message à afficher selon la liste des messages du Store alertMessage.messagesList.
    - message: message à afficher.
    - severity: Style qui influence la couleur en fonction du thème de la application.
                "error", "warning", "info", "success" (défaut: "info")


- Paramètres optionnels:
  {dba: string, autoHideDuration: int, anchorOrigin:{vertical: string, horizontal: string}, replacementStrings: [string, ...]}

    - dba: Base de données dans laquelle récupérer les messages:
            "objetsLangues2", "messagesLangues" (défaut: "messagesLangues")
    autoHideDuration: Durée de l'affichage (défaut: 6000 (6 sec.), null pour permanent)
    anchorOrigin: Définition de la position d'affichage:
      - vertical: "top" ou "bottom" (défaut: "bottom")
      - horizontal: "left", "center" ou "right" (défaut: "center")    
    - replacementStrings: Array de strings qui remplaceront les caractères %s


- Structure:
    dispatch(showSnackbarMessage({
      dba: ""
      messageId: ou message: "",
      severity: "",
      autoHideDuration: 0,    
      anchorOrigin: { vertical: "", horizontal: "" },
      replacementStrings: [""]
    }))


- InitialState:
    snackbar: {
      show: false,
      dba: "messagesLangues",
      message: "",
      severity: "info",
      autoHideDuration: 6000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    }


- Exemple simplifié:
    dispatch(showSnackbarMessage(
      {
        messageId: "fc_wr_matrice_competence_01",
        severity: "error"
      }
    ))
    
    ou
    
    dispatch(showSnackbarMessage(
      {
        message: "votre message",
        severity: "error"
      }
    ))

    
- Exemple complet:
    dispatch(showSnackbarMessage(
      {
        dba: "objetsLangues2"
        messageId: "web_confirmation_enregistrement",
        severity: "error",
        autoHideDuration: 10000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" }
      }
    ))

    ou

    dispatch(showSnackbarMessage(
      {
        message: "votre message",
        severity: "error",
        autoHideDuration: 10000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" }
      }
    )) 
*/

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { hideSnackbarMessage } from "store/slices/MessagesSystemSlice";

export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const { message, severity, autoHideDuration, anchorOrigin, show } = useSelector((state) => state.messagesSystem.snackbar);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbarMessage());
  };

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      sx={{ whiteSpace: 'pre-line' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
