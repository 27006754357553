import LocalStorageService from "Services/LocalStorageService";
import {setLanguageState} from "../slices/AuthenticationSlice";
import {setLocale} from "../slices/PersoSlice";

const localStorageService = LocalStorageService.getService();

const setLocalStorage = (store, lang) => {
  localStorageService.setLanguage(lang);
  store.dispatch(setLanguageState(lang));
  store.dispatch(setLocale(lang.id));
};

const localStorage = store => next => action => {
  switch (action.type) {
    case 'authentication/login/fulfilled':
    case 'authentication/loginAzureAD/fulfilled':
      if(typeof action.payload === 'object' &&
          !Array.isArray(action.payload) &&
          action.payload !== null &&
          action.payload.success === true &&
          !action.payload.erreurMessage &&
          !action.payload.tempPassword) {
        setLocalStorage(store, action.payload.language);
      }
      break;
    case 'authentication/authentifyCodeMultifacteur/fulfilled':
      if(typeof action.payload === 'object' &&
          !Array.isArray(action.payload) &&
          action.payload.infos !== null &&
          action.payload.infos.success === true &&
          !action.payload.infos.erreurMessage &&
          !action.payload.infos.tempPassword) {
        setLocalStorage(store, action.payload.infos.language);
      }
      break;
    case 'authentication/setLanguage/fulfilled':
      setLocalStorage(store, action.payload.language);
      break;
    // no default
  }

  return next(action);
}

export default localStorage;