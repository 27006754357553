import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  LOGIN,
  LOGOUT,
  EMP_LOGGED,
  SET_LANGUAGE,
  GET_AZURE_AD_CONFIG,
  LOGIN_AD_AZURE, AUTH_CODE_MULTIFACTEUR,
} from 'store/UrlConstants';

const initialState = {
  status: 'loggedOut',
  expiredSession: false,
  errorMsg: '',
  loggedUserData: {
    noEmploye: "",
    nom: "",
    titre: "",
    telRes: "",
    telBur: "",
    telCell: "",
    telExtension: "",
    dteEmbauche: "",
    email: "",
    adresse: "",
    ville: "",
    province: "",
    codePostal: "",
    site: "",
    inactif: 0,
    source: '',
    superieurImmediat: '',
    telephonePage: '',
    employeStatut: '',
    quartDeTravail: '',
    equipeTravail: '',
    dateNaissance: '',
    datePermanence: '',
    autreIdentifiant1: '',
    autreIdentifiant2: '',
    autreIdentifiant3: '',
    associeA: '',
    categContactId: '',
    codePostes: [],
    image: '',
    employeId: '',
    noTypePersonne: '',
    language: '',
    consultationNombre: '',
    consultationUnite: '',
    isSuperAdmin: false,
    isSuperRH: false,
    isAdminSite: false
  },
  language: null,
  loadInProgress: false,
  logInProgress: false,
  reloadLocale: true,
  refreshingToken: false,
  azureADConfig: {
    indSynchroActive: 0,
    clientId: "",
    url: "",
    loaded: false
  }
};

export const login = createAsyncThunk('authentication/login', async ({ user, pass, language, rememberMe, mfaToken }, thunkAPI) => {
  const response = await axios.post(LOGIN, { Username: user, Password: pass, Language: language, rememberMe: rememberMe, mfaToken: mfaToken });

  return response.data;
});

export const logout = createAsyncThunk('authentication/logout', async () => {
  const response = await axios.get(LOGOUT);

  return response.data;
});

export const fetchLoggedUserData = createAsyncThunk("authentication/loggedUserData", async () => {
  const response = await axios.get(EMP_LOGGED);
  return response.data;
});

export const setLanguage = createAsyncThunk('authentication/setLanguage', async (language) => {
  const response = await axios.post(SET_LANGUAGE, language);

  return response.data;
});

export const getAzureADConfig = createAsyncThunk('authentication/getAzureADConfig', async () => {
  const response = await axios.get(GET_AZURE_AD_CONFIG);

  return response.data;
});

export const loginAzureAD = createAsyncThunk('authentication/loginAzureAD', async (params, thunkAPI) => {
  const response = await axios.post(LOGIN_AD_AZURE, params);

  return response.data;
});

export const authentifyCodeMultifacteur = createAsyncThunk('authentication/authentifyCodeMultifacteur', async (params, thunkAPI) => {
  const response = await axios.post(AUTH_CODE_MULTIFACTEUR, params);

  return response.data;
});

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    bootstrapLogin(state, action) {
      state.status = 'loggedIn';
    },
    setSessionExpired(state, action) {
      state.expiredSession = action.payload;
    },
    setErrorMsg(state, action) {
      state.errorMsg = action.payload;
    },
    setRefreshingToken(state, action) {
      state.refreshingToken = action.payload;
    },
    setLanguageState(state, action) {
      state.language = action.payload;
    },
    setReloadLocale(state, action) {
      state.reloadLocale = action.payload;
    },
    manualLogout(state, action) {
      state.status = 'loggedOut';
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      if (typeof action.payload === 'object' && !Array.isArray(action.payload) && action.payload !== null) {
        if (action.payload.success === false && action.payload.erreurMessage) {
          // Erreur rencontrée
          state.status = 'failed';
          state.errorMsg = action.payload.erreurMessage;
        } else if (action.payload.success === true && action.payload.erreurMessage) {
          // Login MFA
          state.status = 'validation';
          state.errorMsg = action.payload.erreurMessage;
        } else if (action.payload.success === true && action.payload.tempPassword) {
          // Changement MDP temporaire
          state.status = 'passwordChange';
        } else if (action.payload.success === true && !action.payload.erreurMessage) {
          // Succès login
          state.status = 'loggedIn';
          state.reloadLocale = true;
        }
      }
      state.logInProgress = false;
    },
    [login.pending]: (state, action) => {
      state.loggedUserData = initialState.loggedUserData;
      state.status = 'loading';
      state.logInProgress = true;
    },
    [login.rejected]: (state, action) => {
      state.status = 'failed';
      state.logInProgress = false;
    },
    [logout.pending]: (state, action) => {
      state.logInProgress = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.status = 'loggedOut';
      state.logInProgress = false;
    },
    [logout.rejected]: (state, action) => {
      state.status = 'loggedOut';
      state.logInProgress = false;
    },
    [fetchLoggedUserData.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchLoggedUserData.fulfilled]: (state, action) => {
      state.loggedUserData = action.payload;
      state.loadInProgress = false;
    },
    [loginAzureAD.fulfilled]: (state, action) => {
      if (typeof action.payload === 'object' &&
        !Array.isArray(action.payload) &&
        action.payload !== null) {
        state.status = 'loggedIn';
        state.reloadLocale = true;
      } else if (typeof action.payload === 'string') {
        state.status = 'failed';
        state.errorMsg = action.payload;
      }
    },
    [loginAzureAD.pending]: (state, action) => {
      state.status = 'loading';
      state.loggedUserData = initialState.loggedUserData;
    },
    [loginAzureAD.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [authentifyCodeMultifacteur.fulfilled]: (state, action) => {
      if (typeof action.payload === 'object' &&
        !Array.isArray(action.payload) &&
        action.payload !== null) {
        state.status = 'loggedIn';
        state.reloadLocale = true;
      } else if (typeof action.payload === 'string') {
        state.status = 'failed';
        state.errorMsg = action.payload;
      }
    },
    [authentifyCodeMultifacteur.pending]: (state, action) => {
      state.status = 'loading';
    },
    [authentifyCodeMultifacteur.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [getAzureADConfig.fulfilled]: (state, action) => {
      state.azureADConfig = action.payload;
    }
  },
});

export const loggedUserSelector = state => state.authentication.loggedUserData;

export const getLoggedUserId = createSelector(loggedUserSelector, loggedUserData => loggedUserData.employeId);
export const getLoggedUserName = createSelector(loggedUserSelector, loggedUserData => loggedUserData.nom);
export const getLoggedUserNoEmploye = createSelector(loggedUserSelector, loggedUserData => loggedUserData.noEmploye);
export const getLoggedUserLangue = createSelector(loggedUserSelector, loggedUserData => loggedUserData.lastUsedLanguage);
export const getLoggedUserSiteCode = createSelector(loggedUserSelector, loggedUserData => loggedUserData.site);
export const getLoggedUserEmail = createSelector(loggedUserSelector, loggedUserData => loggedUserData.email);

export const isAzureADEnabled = createSelector(state => state.authentication.azureADConfig, config => {
  return config.indSynchroActive === 1;
});

export const { bootstrapLogin, setSessionExpired, setLanguageState, setReloadLocale, setRefreshingToken, manualLogout, setErrorMsg } = authenticationSlice.actions;

export default authenticationSlice.reducer;