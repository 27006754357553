// API BASE URLS ------------------------------------------------------------------- //
//const HOST = process.env.NODE_ENV === 'development' ? '' : 'http://localhost:5000';
const HOST = "";
const API = HOST + "/api/";
/*const DOCUMENT_HOST =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : "";*/
const DOCUMENT_HOST = window.location.protocol + "//" + window.location.host;
const DOCUMENT_API = DOCUMENT_HOST + "/api/";

// AUTHENTICATION ------------------------------------------------------------------ //
const AUTHENTICATION = API + "Authentication/";
export const LOGIN = AUTHENTICATION + "Login";
export const LOGOUT = AUTHENTICATION + "Logout";
export const REFRESH_TOKEN = AUTHENTICATION + "Refresh";
export const SET_LANGUAGE = AUTHENTICATION + "SetLanguage";
export const LOGIN_AD_AZURE = AUTHENTICATION + "LoginAzure";
export const AUTH_CODE_MULTIFACTEUR =
  AUTHENTICATION + "authentifyCodeMultifacteur";

const UNAUTH_CONFIG = API + "UnauthenticatedConfig/";
export const GET_AZURE_AD_CONFIG = UNAUTH_CONFIG + "GetAzureADConfig";

// AnalyseDeRisques ----------------------------------------------------------------//
const AnalyseDeRisques = API + "AnalyseDeRisques/";
export const ANALYSE_DERISQUES_LOAD_PROCEDELISTE =
  AnalyseDeRisques + "LoadProcedesListe";
export const ANALYSE_DERISQUES_GET_PROCEDE = AnalyseDeRisques + "GetProcede";
export const ANALYSE_DERISQUES_ADD_PROCEDE = AnalyseDeRisques + "AddProcede";
export const ANALYSE_DERISQUES_UPDATE_PROCEDE =
  AnalyseDeRisques + "UpdateProcede";
export const ANALYSE_DERISQUES_DELETE_PROCEDE =
  AnalyseDeRisques + "DeleteProcede";
export const ANALYSE_DERISQUES_GET_ARBORESCENSE_INFO =
  AnalyseDeRisques + "GetArborescenseInfo";
export const ANALYSE_DERISQUES_PROCEDE_CHANGE_ACTIVE =
  AnalyseDeRisques + "ProcedeChangeActive";
export const ANALYSE_DERISQUES_LOAD_ARBORESCENSE =
  AnalyseDeRisques + "LoadArborescense";
export const ANALYSE_DERISQUES_LOAD_UNITES_LISTE =
  AnalyseDeRisques + "LoadUnitesListe";
export const ANALYSE_DERISQUES_LOAD_UNITES = AnalyseDeRisques + "LoadUnites";
export const ANALYSE_DERISQUES_GET_UNITE = AnalyseDeRisques + "GetUnite";
export const ANALYSE_DERISQUES_ADD_UNITE = AnalyseDeRisques + "AddUnite";
export const ANALYSE_DERISQUES_UPDATE_UNITE = AnalyseDeRisques + "UpdateUnite";
export const ANALYSE_DERISQUES_DELETE_UNITE = AnalyseDeRisques + "DeleteUnite";
export const ANALYSE_DERISQUES_UNITE_CHANGE_ACTIVE =
  AnalyseDeRisques + "UniteChangeActive";
export const ANALYSE_DERISQUES_LOAD_COMPOSANTES_LISTE =
  AnalyseDeRisques + "LoadComposantesListe";
export const ANALYSE_DERISQUES_LOAD_TYPE_COMPOSANTES_LISTE =
  AnalyseDeRisques + "LoadTypeComposantesListe";
export const ANALYSE_DERISQUES_LOAD_COMPOSANTES =
  AnalyseDeRisques + "LoadComposantes";
export const ANALYSE_DERISQUES_GET_COMPOSANTE =
  AnalyseDeRisques + "GetComposante";
export const ANALYSE_DERISQUES_ADD_COMPOSANTE =
  AnalyseDeRisques + "AddComposante";
export const ANALYSE_DERISQUES_UPDATE_COMPOSANTE =
  AnalyseDeRisques + "UpdateComposante";
export const ANALYSE_DERISQUES_DELETE_COMPOSANTE =
  AnalyseDeRisques + "DeleteComposante";
export const ANALYSE_DERISQUES_COMPOSANTE_CHANGE_ACTIVE =
  AnalyseDeRisques + "ComposanteChangeActive";
export const ANALYSE_DERISQUES_LOAD_PT_ECHANTILLON_LISTE =
  AnalyseDeRisques + "LoadPtEchantillonListe";
export const ANALYSE_DERISQUES_LOAD_PT_ECHANTILLONS =
  AnalyseDeRisques + "LoadPtEchantillons";
export const ANALYSE_DERISQUES_GET_PT_ECHANTILLON =
  AnalyseDeRisques + "GetPtEchantillon";
export const ANALYSE_DERISQUES_ADD_PT_ECHANTILLON =
  AnalyseDeRisques + "AddPtEchantillon";
export const ANALYSE_DERISQUES_UPDATE_PT_ECHANTILLON =
  AnalyseDeRisques + "UpdatePtEchantillon";
export const ANALYSE_DERISQUES_DELETE_PT_ECHANTILLON =
  AnalyseDeRisques + "DeletePtEchantillon";
export const ANALYSE_DERISQUES_PT_ECHANTILLON_CHANGE_ACTIVE =
  AnalyseDeRisques + "PtEchantillonChangeActive";
export const ANALYSE_DERISQUES_GET_CRITERES_MULTI_INFO =
  AnalyseDeRisques + "GetActionCriteresMultiInfo";
export const ANALYSE_DERISQUES_GET_CRITERES_MULTI_TYPE =
  AnalyseDeRisques + "GetActionCriteresMultiType";

//#region Commun
const COMMUN = API + "Commun/";
export const COMMUN_GET_COURRIEL = COMMUN + "GetCourriel";
export const COMMUN_GET_PING_RESULT = COMMUN + "GetPingResult";
//#endregion


// Historique ---------------------------------------------------------------------- //
const Historique = API + "Historique/";
export const HISTORIQUE_LOAD_EVENEMENTS = Historique + "LoadEvenements";

// SECURITY ------------------------------------------------------------------------ //
const USAGER = API + "Usager/";
export const MENU_DATA = USAGER + "MenuData";

export const GET_SEC_USAGER_VM_FC = USAGER + "GetOneSecUsagerVmFc";
export const ADD_ONE_SEC_USAGER = USAGER + "AddOneSecUsager";
export const UPDATE_ONE_SEC_USAGER = USAGER + "UpdateOneSecUsager";
export const RESET_SEC_USAGER_PASSWORD = USAGER + "ResetSecUsagerPassword";
export const DELETE_ONE_SEC_USAGER = USAGER + "DeleteOneSecUsager";
export const ENVOIS_CURRIEL_SEC_USAGER = USAGER + "SecUsagerEnvoisCourriel";

export const GET_ALL_CARTE_ACCESS_BY_USAGER_ID =
  USAGER + "GetAllCarteAccesByUsagerId";
export const GET_ONE_CARTE_ACCESS_BY_USAGER_ID =
  USAGER + "GetOneCarteAccesVmFc";

export const GET_SEC_GROUPES_DISP_BY_USAGER =
  USAGER + "GetSecGroupesDispByUsager";
export const GET_SEC_GROUPES_ASSOCIE_USAGER =
  USAGER + "GetSecGroupesAssocieUsager";
export const ADD_SEC_GROUPES_USAGER = USAGER + "AddSecGroupesUsager";
export const DELETE_SEC_GROUPES_USAGER = USAGER + "DeleteSecGroupesUsager";

export const CHANGE_SEC_USAGER_PASSWORD_LOGGED_IN =
  USAGER + "ChangeSecUsagerPasswordLoggedIn";

const UNAUTH_USAGER = API + "UnauthenticatedUsager/";
export const REQUEST_CHANGE_SEC_USAGER_PASSWORD =
  UNAUTH_USAGER + "RequestChangeSecUsagerPassword";
export const CHANGE_SEC_USAGER_PASSWORD =
  UNAUTH_USAGER + "ChangeSecUsagerPassword";
export const LOAD_POLPWPOL_BY_USERNAME =
  UNAUTH_USAGER + "LoadPolPwPolByUsername";

// PERSO --------------------------------------------------------------------------- //
const PERSO = API + "Perso/";
export const FETCH_PERSO_ALL = PERSO + "All";
const UNAUTH_PERSO = API + "UnauthenticatedPerso/";
export const FETCH_UNAUTH_PERSO_ALL = UNAUTH_PERSO + "All";

// CRITERIA ------------------------------------------------------------------------ //
const CRITERA = API + "Criteria/";
export const FETCH_DECISIONS = CRITERA + "Decisions";
export const FETCH_DECISIONS_OF_CODE = CRITERA + "Decisions";
export const FETCH_VOLETS = CRITERA + "Volets";
export const FETCH_PROCEDES = CRITERA + "Procedes";
export const FETCH_UNITES = CRITERA + "Unites";
export const FETCH_COMPOSANTS = CRITERA + "Composants";
export const FETCH_PT_ECHANTILLONS = CRITERA + "PtEchantillons";
export const LOAD_ARTICLE_ISO_ACTIF = CRITERA + "ArticleIsoActif";
export const GET_OPTION_REGROUPEMENTS = CRITERA + "GetOptionRegroupements";
export const FETCH_POSTES = CRITERA + "Postes";
export const FETCH_TYPE_PREMIER_SOIN = CRITERA + "TypePremierSoin";
export const FETCH_INTERVENANT_EXTERNE = CRITERA + "IntervenantExterne";
export const FETCH_PARTIE_DU_CORPS = CRITERA + "PartieDuCorps";
export const FETCH_TYPE_BLESSURE = CRITERA + "TypeBlessure";
export const FETCH_TYPE_EVENEMENT = CRITERA + "TypeEvenement";
export const FETCH_TYPE_ACTIVITE = CRITERA + "TypeActivite";
export const FETCH_TYPE_ASSIGNATION = CRITERA + "TypeAssignation";
export const FETCH_TYPE_RESTRICTION = CRITERA + "TypeRestriction";
export const FETCH_CATEGORIE_COUT_AU = CRITERA + "CategorieCoutAu";
export const FETCH_PRODUIT_LIGNE_FAMILLE = CRITERA + "ProduitLigneFamille";
export const FETCH_FAMILLE_PRODUIT = CRITERA + "FamilleProduit";
export const FETCH_LIGNE_PRODUIT = CRITERA + "LigneProduit";
export const FETCH_CRITERIA_AUDIT = CRITERA + "Audit";
export const FETCH_TYPE_GRAVITE = CRITERA + "TypeGravite";
export const FETCH_EQUIPEMENT_PROTECTION = CRITERA + "EquipementProtection";
export const FETCH_GET_MOYENS_SQDM2017 = CRITERA + "GetMoyensSqdm2017";
export const GET_WF_ETAPE = CRITERA + "GetWfEtape";

// CompanyContactDetails ------------------------------------------------------------------------ //
export const FETCH_COMPANY_CONTACT_DETAILS = API + "CompanyContactDetails";

// GLOBAL SEARCH ------------------------------------------------------------------- //
const GLOBAL_SEARCH = API + "GlobalSearch/";
const UNAUTH_GLOBAL_SEARCH = API + "UnauthenticatedGlobalSearch/";
export const GS_SEARCH = GLOBAL_SEARCH + "Search";
export const UNAUTH_GS_SEARCH_GED_PUBLIC = UNAUTH_GLOBAL_SEARCH + "SearchGedPublic";
export const GS_LOAD_DEMANDES_A_TRAITER =
  GLOBAL_SEARCH + "LoadDemandesATraiter";
export const GS_TRAITE_DEMANDE = GLOBAL_SEARCH + "TraiteDemande";

// DOCUMENTS ----------------------------------------------------------------------- //
const DOCUMENT = DOCUMENT_API + "Document/";
const UNAUTH_DOCUMENT = DOCUMENT_API + "UnauthenticatedDocument/";
export const LOAD_DOCUMENT = DOCUMENT + "Get";
export const OPEN_DOCUMENT_DAV = DOCUMENT_API + "dav";
export const UNAUTH_GET_FICHIER_DOCUMENT_DOC_MANAGE =
  UNAUTH_DOCUMENT + "GetDocManage";

// GESTION DOCUMENTAIRE MODULE ----------------------------------------------------- //
const GED = API + "GestionDocumentaire/";
const UGED = API + "UnauthenticatedGestionDocumentaire/";
const GEDR = API + "GestionDocumentaireRapports/";

//Criterias
export const GET_ALL_GED_CRITERIAS_EMPLACEMENT = GED + "CriteriasEmplacement";
export const GET_ALL_GED_CRITERIAS_RESPONSABLE = GED + "CriteriasResponsable";
export const GET_ALL_GED_CRITERIAS_AUTEUR = GED + "CriteriasAuteur";
export const GET_ALL_GED_CRITERIAS_TYPE_DOCUMENT =
  GED + "CriteriasTypeDocument";
export const GET_ALL_GED_CRITERIAS_TYPE_SUPPORT = GED + "CriteriasTypeSupport";
export const GET_ALL_GED_CRITERIAS_LOCALISATION = GED + "CriteriasLocalisation";
export const GET_ALL_GED_CRITERIAS_COTE = GED + "CriteriasCote";
export const GED_CRITERIAS = GED + "Criterias";
export const GET_ALL_GED_DOCUMENT_CRITERIAS = GED + "GetDocumentCriteria";
export const GET_ALL_GED_DOCUMENT_SEARCH = GED + "SearchDocuments";
export const GET_ALL_GED_DOSSIER_CRITERIAS = GED + "GetDossierCriteria";
export const GET_ALL_GED_DOSSIER_SEARCH = GED + "SearchDossiers";
export const GET_ALL_GED_CRITERIAS_TYPE_DOSSIER = GED + "CriteriasTypeDossier";

//Configuration Module
export const GET_ALL_GED_LOAD_CONFIG_MODULE_ONGLET_GED = GED + "LoadGedConfigGed";
export const GET_CONVERSION_PDF_EXTENSIONS = GED + "LoadConversionPdfExtensions";
export const UPDATE_GED_CONFIG_MODULE_ONGLET_GED = GED + "MajGedConfigGed";
export const GET_ALL_GET_CONFIG_MODULE_ONGLET_CNF_RECHERCHE = GED + "LoadGedConfigRecherche";
export const GET_MODELES_DYNAMIQUES_GED_EDIT = GED + "LoadGedConfigModuleGedEdit";
export const UPDATE_MODELES_DYNAMIQUES_GED_EDIT = GED + "MajGedConfigModuleGedEdit";
export const UPDATE_GED_CONFIG_MODULE_ONGLET_CNF_RECHERCHE = GED + "MajGedConfigRecherche";

// Documents
export const GET_ALL_GED_BY_CLASSIFICATION =
  GED + "AllByClassificationByCodeAuto";
export const GET_ALL_GED_LOAD_DOS_COTES = GED + "LoadDosCotesFromCodeAuto";
export const LOAD_DOS_COTE_PATH = GED + "LoadDosCotesPathFromCodeAuto";
export const GET_GED_DOSSIER_CHILDREN = GED + "DossierChildren";
export const GET_GED_DOSSIER_PATH = GED + "DossierPath";
export const LOAD_PLAN_CLASSIFICATION_BY_ID =
  GED + "LoadPlanClassificationById";
export const LOAD_PLAN_CLASSIFICATION_BY_SITE =
  GED + "LoadPlanClassificationBySite";
export const GET_PLAN_CLASSIFICATION_MENU_CONTEXTUEL =
  GED + "GetPlanClassificationMenuContextuel";
export const ADD_GED_HISTORIQUE = GED + "AddHistorique";
export const LOAD_HISTORIQUE = GED + "LoadHistorique";
export const GET_GED_DUPLIQUER_INFO = GED + "GetGedDupliquerInfo";
export const GED_DUPLIQUER_DOCUMENT = GED + "GedDupliquerDocument";
export const GED_DUPLIQUER_DOSSIER = GED + "GedDupliquerDossier";
export const LOAD_FAIRE_DEMANDE_TYPES = GED + "LoadFaireDemandeTypes";
export const LOAD_FAIRE_DEMANDE_PERS = GED + "LoadFaireDemandePers";
export const GED_FAIRE_DEMANDE = GED + "FaireUneDemande";
export const EDITER_DOCUMENT = GED + "EditerDocument";
export const ANNULER_EDITION_DOCUMENT = GED + "AnnulerEditionDocument";
export const LOAD_INTEGRER_DOCUMENTS = GED + "LoadIntegrerDocuments";
export const INTEGRER_DOCUMENT = GED + "IntegrerDocument";
export const LOAD_GED_WORKFLOW = GED + "LoadGedWorkflow";
export const EMETTRE_DOCUMENT_WORKFLOW = GED + "EmettreDocumentWorkflow";
export const ANNULER_WORKFLOW = GED + "AnnulerWorkflow";
export const LOAD_CREER_VERSION_INFO = GED + "LoadCreerVersionInfo";
export const CREER_VERSION = GED + "CreerVersion";
export const DELETE_DOCUMENT = GED + "DeleteDocument";
export const LOAD_CONFIG_LISTE_CONTROLE = GED + "LoadConfigListeControle";
export const UPDATE_CONFIG_LISTE_CONTROLE = GED + "UpdateConfigListeControle";
export const LOAD_DOS_EXEMP_SEC = GED + "LoadDosExempSec";

export const GED_REGISTRE_MODIFICATION_REPORT = GEDR + "GetRegistreDesModificationsReport";
export const GED_WORKFLOW_REPORT = GEDR + "GetConsulterWorkflowReport";

// Dossiers
export const GED_LOAD_DOSSIER_BY_ID = GED + "LoadDossierById";
export const GED_ADD_UPDATE_DOSSIER = GED + "AddUpdateDossier";

// Documents
export const GED_LOAD_DOCUMENT_BY_ID = GED + "LoadDocumentById";
export const GED_LOAD_FAMILLE_ACTIVITE = GED + "LoadFamilleActivite";
export const GED_MAJ_FAMILLE_ACTIVITES = GED + "MajFamilleActivites";
export const GED_NEW_DOSSIER = GED + "NewDossier";
export const GED_NEW_DOCUMENT = GED + "NewDocument";
export const GED_ADD_UPDATE_DOCUMENT = GED + "AddUpdateDocument";
export const GED_LOAD_GED_MODELES_DOCUMENTS = GED + "LoadGedModelesDocuments";
export const GED_IMPORTER_MODELE = GED + "GedImporterModele";
export const GED_IMPORTER_DOC = GED + "GedImporterDoc";

// Ged Groupes
export const GET_ALL_GED_GROUPES = GED + "GetAllGedGroupes";
export const GET_ALL_GED_GROUPES_BY_EMPLOYE = GED + "GetAllGedGroupesByEmploye";
export const LOAD_GED_GROUPES_EDIT = GED + "LoadGedGroupeEdit";
export const MAJ_GED_GROUPE_EDIT = GED + "MajGedGroupeEdit";
export const DELETE_GED_GROUPE = GED + "DeleteGedGroupe";

// MembresGroupes
export const LOAD_MEMBRES_GROUPES = GED + "LoadGedMembreGroupes";
export const MAJ_MEMBRES_GROUPES = GED + "MajMembresGroupes";
export const ADD_MEMBRES_GROUPES = GED + "AddMembresGroupes";
export const MAJ_GED_MEMBRES_GROUPES = GED + "MajGedMembreGroupes";
export const DELETE_MEMBRES_GROUPES = GED + "DeleteMembresGroupes";
export const DUPLIQUER_GED_MEMBRE_GROUPE = GED + "DupliquerGedMembreGroupes";
export const GET_GED_MEMBRE_GROUPE_RAPPORT = GEDR + "GetMembreGroupeGedReport";

// Suivi des demandes
export const LOAD_LISTE_DEMANDES_CRITERES = GEDR + "LoadListeDemandesCriteres";
export const GET_LISTE_DEMANDES_RAPPORT = GEDR + "GetListeDemandesReport";

// Rapport liste documents par type
export const GET_LISTE_DOCUMENTS_TYPE_REPORT = GEDR + "GetListeDocumentsTypeReport";

// Localisations
export const LOAD_LOCALISATIONS = GED + "LoadLocalisation";
export const MAJ_LOCALISATIONS = GED + "MajLocalisation";
export const GET_LOCALISATION_CHG_LOC = GED + "GetLocalisationChgLoc";

// Types de demandes
export const LOAD_GED_TYPES_DEMANDES = GED + "LoadGedTypeDemandeRecherche";
export const LOAD_GED_TYPES_DEMANDES_EDIT = GED + "LoadGedTypeDemandeEdit";
export const ADD_UPDATE_GED_TYPE_DEMANDE = GED + "AddUpdGedTypeDemandeEditTypeDemande";
export const DELETE_GED_TYPE_DEMANDE = GED + "DelGedTypeDemandeEditTypeDemande";
export const UPDATE_GED_TYPE_DEMANDE_EDIT_CONFIGURATION =
  GED + "UpdateGedTypeDemandeEditConfiguration";
export const UPDATE_GED_TYPE_DEMANDE_EDIT_INDICATIONS =
  GED + "UpdateGedTypeDemandeEditIndication";
export const UPDATE_GED_TYPE_DEMANDE_EDIT_PERSONNALISATION =
  GED + "UpdateGedTypeDemandeEditPersonnalisation";

// MODELES DCOUMENTS
export const LOAD_GED_MODEL_DOCUMENT_EDIT = GED + "LoadGedModeleDocumentEdit";
export const DELETE_GED_MODEL_DOCUMENT = GED + "DelGedModeleDocumentEdit";
export const MAJ_GED_MODELE_DOCUMENT = GED + "MajGedModeleDocumentEdit";
export const MAJ_GED_MODELES_DOCUMENT = GED + "MajGedModeleDocumentEdits";
export const MAJ_GED_MODEL_DOCUMENT_LOGO_MODELE = GED + "MajGedModeleDocumentLogoModele";

// DETAILS DES TRAITEMENTS D'HORODATAGE
export const LOAD_GED_HORODATAGE_LOGS = GED + "LoadGedHorodatageLogs";

// MES FORMULAIRES
export const LOAD_FORMULAIRES = GED + "LoadFormulaires";
export const REMPLIR_FORMULAIRE = GED + "RemplirFormulaire";
export const EDITER_FORMULAIRE = GED + "EditerFormulaire";
export const TERMINER_FORMULAIRE = GED + "TerminerFormulaire";
export const SUPPRIMER_FORMULAIRE = GED + "SupprimerFormulaire";
export const DAV_API = DOCUMENT_HOST + "/api/dav"

// EXEMMPLAIRES SECONDAIRES
export const LOAD_EXEMMPLAIRE_SECONDAIRE_CRITERES = GEDR + "LoadExemmplaireSecondaireCriteres";
export const LOAD_EXEMMPLAIRE_SECONDAIRE_RESULTAT = GEDR + "LoadExemmplaireSecondaireResultat";
export const GET_EXEMMPLAIRE_SECONDAIRE_REPORT = GEDR + "GetExemmplaireSecondaireReport";

//UnauthenticatedGestionDocumentaire
export const GET_ALL_UGED_BY_CLASSIFICATION = UGED + "AllByClassification";
export const GET_ALL_UGED_LOAD_DOS_COTES = UGED + "LoadDosCotes";
export const UGED_LOAD_DOS_COTE_PATH = UGED + "LoadDosCotesPathFromCodeAuto";
export const GET_UGED_DOSSIER_CHILDREN = UGED + "DossierChildren";
export const GET_UGED_DOSSIER_PATH = UGED + "DossierPath";
export const UNAUTH_GET_CODE_AUTO_BY_DOS_COTE = UGED + "LoadDocumentByCote";

//Ged traitement
const GED_TRAITEMENT = API + "GestionDocumentaireTraitement/";
export const GET_ALL_LOAD_DOC_CHANGEMENT = GED_TRAITEMENT + "LoadDocChangement";
export const UPDATE_DOC_CHANGEMENT = GED_TRAITEMENT + "UpdateDocChangement";
export const GET_ALL_LOAD_CONVERSION_DOCUMENT_PDFS = GED_TRAITEMENT + "LoadConversionDocumentPdfs";
export const UPDATE_CONVERSION_DOCUMENTS_PDFS = GED_TRAITEMENT + "UpdateConversionDocumentPdfs";

//Demandes à traiter
export const GED_DEMANDES_TRAITER = GED + "LoadDemandesTraiter";
export const GED_UPDATE_DEMANDES_TRAITER = GED + "UpdateDemandesTraiter";

// Dialog - Selection Objet De Connaissance
export const LOAD_CRITERES = GED + "LoadCriteres";
export const SEARCH_DOCUMENTS_GEC = GED + "SearchDocumentsGEC";

//Gestion des types de documents
export const LOAD_GESTION_TYPES_DOCUMENTS = GED + "LoadGestionTypesDocuments";
export const MAJ_GESTION_TYPES_DOCUMENTS = GED + "MajGestionTypesDocuments";

//Rapports
export const GET_LOAD_CRITERES = GEDR + "LoadCriteres";
export const GET_GED_CONFIG_LISTE_CTRL_REPORT = GEDR + "GetConfigurationListeControleReport";
export const GET_REGISTRE_PRET_EXEMPLAIRES_PRINCIPAUX = GEDR + "GetRegistrePretExemplairesPrincipaux";
export const GET_PLAN_CLASSIFICATION = GEDR + "GetPlanClassificationRapport";
export const GET_PLAN_CLASSIFICATION_INDEX = GEDR + "GetPlanClassificationIndexRapport";
export const GET_HISTO_CONSULT_RAPPORT = GEDR + "GetHistoConsultRapport";

// FICHIER DOCUMENT ----------------------------------------------------------------- //
const FICHIER_DOCUMENT = DOCUMENT_API + "FichierDocument/";
export const GET_FICHIER_DOCUMENT = FICHIER_DOCUMENT + "Get";
export const GET_FICHIER_DOCUMENT_INFO = FICHIER_DOCUMENT + "GetDocInfo";

// HYPERLIEND ---------------------------------------------------------------------- //
const HYPERLIEN_DOCUMENT = DOCUMENT_API + "HyperlienDocument/";
const UNAUTHENTICATED_HYPERLIEN_DOCUMENT =
  DOCUMENT_API + "UnauthenticatedHyperlienDocument/";
export const GET_HYPERLIEN_DOCUMENT = HYPERLIEN_DOCUMENT + "Get";
export const GET_HYPERLIEN_DOCUMENT_UNAUTH =
  UNAUTHENTICATED_HYPERLIEN_DOCUMENT + "Get";
export const GET_HYPERLIEN_DOCUMENT_INFO = HYPERLIEN_DOCUMENT + "GetDocInfo";
export const GET_HYPERLIEN_DOCUMENT_TYPELIEN =
  HYPERLIEN_DOCUMENT + "GetTypeLien";
export const GET_HYPERLIEN_DOCUMENT_NEW =
  HYPERLIEN_DOCUMENT + "NewHyperlienDocument";
export const ADD_HYPERLIEN_DOCUMENT =
  HYPERLIEN_DOCUMENT + "AddHyperlienDocument";
export const DELETE_HYPERLIEN_DOCUMENT =
  HYPERLIEN_DOCUMENT + "DeleteHyperlienDocument";
export const UPDATE_HYPERLIEN_DOCUMENT =
  HYPERLIEN_DOCUMENT + "UpdateHyperlienDocument";
export const UPDATE_HYPERLIEN_DOCUMENTS =
  HYPERLIEN_DOCUMENT + "UpdateHyperlienDocuments";
export const LOAD_HYPERLIEN_DOCUMENTS =
  HYPERLIEN_DOCUMENT + "LoadHyperlienDocuments";
export const LOAD_UNAUTHENTICATED_HYPERLIEN_DOCUMENTS =
  UNAUTHENTICATED_HYPERLIEN_DOCUMENT + "LoadHyperlienDocuments";

// Commun - Document --------------------------------------------------------------------- //
export const DOC = API + "Doc/";
export const UNAUTH_DOC = API + "UnauthenticatedDoc/";
export const GET_DOC = DOC + "Get";
export const GET_INFO_DOC = DOC + "GetInfo";
export const UNAUTH_GET_DOC = UNAUTH_DOC + "Get";
export const UNAUTH_GET_INFO_DOC = UNAUTH_DOC + "GetInfo";

// NIGHTWORKER --------------------------------------------------------------------- //
export const NIGHTWORKER = API + "NightWorker/";
export const ADD_EMAIL_TASKS = NIGHTWORKER + "AddEmailTasks";
export const ADD_EMAIL_TASK = NIGHTWORKER + "AddEmailTask";

// EMAIL --------------------------------------------------------------------------- //
export const COURRIEL = API + "Courriel/";

// FORMATION ----------------------------------------------------------------------- //
const FORMATION = API + "Formation/";

export const FORMATION_NEW = FORMATION + "New";
export const FORMATION_ADD = FORMATION + "Add";
export const FORMATION_UPDATE = FORMATION + "Update";
export const FORMATION_DELETE = FORMATION + "Delete";
export const FORMATION_DUPLIQUER_INFOS = FORMATION + "DupliquerInfo";
export const FORMATION_DUPLIQUER = FORMATION + "Dupliquer";
export const FORMATION_ONE_LOGGED = FORMATION + "OneLogged";
export const FORMATION_LOAD_COMPETENCES = FORMATION + "LoadCompetences";
export const FORMATION_MAJ_COMPETENCES = FORMATION + "MajCompetences";
export const FORMATION_ADD_COMPETENCE = FORMATION + "AddCompetence";
export const FORMATION_DELETE_COMPETENCE = FORMATION + "DeleteCompetence";
export const FORMATION_UPDATE_INSCRIPTION = FORMATION + "UpdateInscription";
export const FORMATION_LOAD_INSCRIPTIONS = FORMATION + "LoadInscriptions";
export const FORMATION_MAJ_INSCRIPTIONS = FORMATION + "MajInscriptions";
export const FORMATION_DELETE_INSCRIPTION = FORMATION + "DeleteInscription";
export const FORMATION_ADD_INSCRIPTION = FORMATION + "AddInscription";
export const FORMATION_LOAD_OPTIONS = FORMATION + "GetOptions";
export const FORMATION_UPDATE_OPTIONS = FORMATION + "UpdateOptions";
export const FORMATION_LOAD_DOCUMENTS = FORMATION + "LoadDocuments";
export const FORMATION_UPDATE_DOCUMENTS = FORMATION + "UpdateDocuments";
export const FORMATION_ADD_DOCUMENT = FORMATION + "AddDocument";
export const FORMATION_DELETE_DOCUMENT = FORMATION + "DeleteDocument";
export const FORMATION_LOAD_EXAMEN_QUESTIONS =
  FORMATION + "LoadExamenQuestions";
export const FORMATION_ADD_EXAMEN_QUESTION = FORMATION + "AddExamenQuestion";
export const FORMATION_UPDATE_EXAMEN_QUESTIONS =
  FORMATION + "UpdateExamenQuestions";
export const FORMATION_DELETE_EXAMEN_QUESTION =
  FORMATION + "DeleteExamenQuestion";
export const FORMATION_IMPORT_EXAMEN_QUESTIONS =
  FORMATION + "ImportExamenQuestions";
export const FORMATION_ADD_EXAMEN_REPONSE = FORMATION + "AddExamenReponse";
export const FORMATION_DELETE_EXAMEN_REPONSE =
  FORMATION + "DeleteExamenReponse";
export const FORMATION_LOAD_EXAMEN_INFO = FORMATION + "LoadExamenInfo";
export const FORMATION_UPDATE_EXAMEN_INFO = FORMATION + "UpdateExamenInfo";
export const FORMATION_LOAD_PARTIES = FORMATION + "LoadParties";
export const FORMATION_UPDATE_PARTIE_QUESTIONS =
  FORMATION + "UpdatePartieQuestions";
export const FORMATION_UPDATE_PARTIE_DOCUMENTS =
  FORMATION + "UpdatePartieDocuments";
export const FORMATION_UPDATE_PARTIE_SECTIONS =
  FORMATION + "UpdatePartieSections";

// Competences
const COMPETENCE = API + "Competence/";

export const COMPETENCE_SEARCH = COMPETENCE + "Search/Search";
export const COMPETENCE_LOAD_COMPETENCES_FROM_FORMATION =
  COMPETENCE + "LoadCompetencesFromFormation";
export const COMPETENCE_LOAD_COMPETENCES_FROM_INSCRIPTION =
  COMPETENCE + "LoadCompetencesFromInscription";
export const COMPETENCE_UPDATE_COMPETENCES_EMPLOYES =
  COMPETENCE + "UpdateCompetencesEmployes";  
export const COMPETENCE_UPDATE_COMPETENCES_EMPLOYE =
  COMPETENCE + "UpdateCompetencesEmploye";
export const COMPETENCE_DELETE_COMPETENCES_EMPLOYE =
  COMPETENCE + "DeleteCompetencesEmploye";
export const COMPETENCE_DELETE_COMPETENCES_EMPLOYES_FORMATION =
  COMPETENCE + "DeleteCompetencesEmployesFormation";  

//Competence Criteria
const COMPETENCE_CRITERIA = COMPETENCE + "Criterias/";
export const GET_ALL_NIVEAU_MAITRISE = COMPETENCE_CRITERIA + "NiveauMaitrise";
export const GET_ALL_FORMATIONS_ASSOCIEES = COMPETENCE_CRITERIA + "FormationAssocie";

// Competences Employe
const COMPETENCE_EMPLOYE = API + "CompetenceEmploye/";
export const ADD_COMPETENCE_EMPLOYE = COMPETENCE_EMPLOYE + "Add";
export const LOAD_MAJ_COMPETENCE = COMPETENCE_EMPLOYE + "LoadMajCompetence";
export const UPDATE_MAJ_COMPETENCE = COMPETENCE_EMPLOYE + "UpdateMajCompetence";
export const LOAD_COMPETENCE_MAJ_RAPIDE =
  COMPETENCE_EMPLOYE + "LoadCompetenceMajRapide";
export const UPDATE_COMPETENCE_MAJ_RAPIDE =
  COMPETENCE_EMPLOYE + "UpdateCompetenceMajRapide";
export const GET_POSTES_OCCUPES = COMPETENCE_EMPLOYE + "GetPostesOccupes";

// Coûts
export const FORMATION_LOAD_COUTS = FORMATION + "LoadCouts";
export const FORMATION_MAJ_COUTS = FORMATION + "MajCouts";
export const FORMATION_UPDAATE_COUTS_ADMISSIBLES = FORMATION + "UpdateCoutsAdmissibles";

// Presences
export const FORMATION_LOAD_PRESENCES = FORMATION + "LoadPresences";
export const FORMATION_MAJ_PRESENCES = FORMATION + "MajPresences";
export const FORMATION_ADD_PRESENCE = FORMATION + "AddPresence";
export const FORMATION_UPDATE_PRESENCE = FORMATION + "UpdatePresence";
export const FORMATION_DELETE_PRESENCE = FORMATION + "DeletePresence";

// Temps Formateur
export const FORMATION_LOAD_TEMPS_FORMATEUR = FORMATION + "LoadTempsForms";
export const FORMATION_MAJ_TEMPS_FORMATEUR = FORMATION + "MajTempsForms";
export const FORMATION_GET_TEMPS_FORMATEUR = FORMATION + "GetTempsForms";
export const FORMATION_ADD_TEMPS_FORMATEUR = FORMATION + "AddTempsForm";
export const FORMATION_UPDATE_TEMPS_FORMATEUR = FORMATION + "UpdateTempsForm";
export const FORMATION_DELETE_TEMPS_FORMATEUR = FORMATION + "DeleteTempsForm";

// Calendriers
export const FORMATION_LOAD_CALENDRIERS = FORMATION + "LoadFormationCalendriers";
export const FORMATION_NEW_CALENDRIER = FORMATION + "NewFormationCalendrier";
export const FORMATION_ADD_CALENDRIER = FORMATION + "AddFormationCalendrier";
export const FORMATION_UPDATE_CALENDRIER = FORMATION + "UpdateFormationCalendrier";
export const FORMATION_DELETE_CALENDRIER = FORMATION + "DeleteFormationCalendrier";

// Search
export const FORMATION_SEARCH = FORMATION + "Search";

// Criteria
const FORMATION_CRITERIAS = FORMATION + "Criterias/";

export const FOR_FECH_CODE_FORMATION = FORMATION_CRITERIAS + "CodeFormation";
export const FETCH_MOYENS_SQDM = FORMATION_CRITERIAS + "MoyensSqdm";
export const FETCH_MOYENS_SQDM_SITES = FORMATION_CRITERIAS + "MoyensSqdmSites";
export const FETCH_LIEU_FORMATION = FORMATION_CRITERIAS + "LieuFormation";
export const FETCH_FORMATEUR = FORMATION_CRITERIAS + "Formateur";
export const FETCH_COMPETENCE = FORMATION_CRITERIAS + "CodeCompetence";
export const FETCH_EMPLOYES_INSCRITS = FORMATION_CRITERIAS + "EmployesInscrits";

// FORMATION ET COMPÉTENCES -------------------------------------------------------- //
const FC = API + "FormationCompetences/";

export const COMP_DESACTIVER_REACTIVER = FC + "DesactiverReactiverCompetence";
export const COMP_DUPLIQUER = FC + "DupliquerCompetence";
export const GET_COMPETENCE = FC + "GetCompetence";
export const DELETE_COMPETENCE = FC + "DeleteCompetence";
export const ADD_UPDATE_COMPETENCE = FC + "AddUpdateCompetence";
export const LOADPOSTES = FC + "LoadPostes";
export const ADD_UPDATE_POSTE = FC + "AddUpdatePoste";
export const ACTIVATE_DEACTIVATE_POSTE = FC + "DesactiverReactiverPoste";
export const DELETE_POSTE = FC + "DeletePoste";
export const LOAD_POSTE_COMPETENCES = FC + "LoadPosteCompetences";
export const LOAD_POSTE_COMPETENCES_SAISIE_RAPIDE = FC + "LoadPosteCompetencesSaisieRapide";
export const ADD_POSTE_COMPETENCES_SAISIE_RAPIDE = FC + "AddPosteCompetencesSaisieRapide";
export const ADD_POSTE_COMPETENCES_BATCH = FC + "MajPosteCompetences";
export const LOAD_POSTE_EMPLOYES = FC + 'LoadPostesPosteOccupes';
export const ADD_POSTE_EMPLOYES_BATCH = FC + 'MajPostesPosteOccupe';
export const LOAD_POSTE_EPI = FC + 'LoadPosteEquipementProtections';
export const ADD_POSTE_EPI_BATCH = FC + 'MajPosteEquipementProtection';
export const DUPLIQUER_POSTE = FC + 'DupliquerPoste';

// Competence Postes
export const GET_ALL_COMPETENCE_POSTES = FC + "LoadCompetencePostes";
export const ADD_COMPETENCE_POSTE = FC + "AddCompetencePoste";
export const UPDATE_COMPETENCE_POSTE = FC + "UpdateCompetencePoste";
export const DELETE_COMPETENCE_POSTE = FC + "DeleteCompetencePoste";
export const MAJ_COMPETENCE_POSTES = FC + "MajCompetencePostes";
export const GET_COMPETENCE_POSTES_SAISIE_RAPIDE = FC + "LoadCompetencePostesSaisieRapide";
export const ADD_COMPETENCE_POSTES_SAISIE_RAPIDE = FC + "AddCompetencePostesSaisieRapide";

// Competence Employes
export const GET_COMPETENCE_EMPLOYES = FC + "LoadCompetenceEmployes";
export const MAJ_COMPETENCE_EMPLOYES = FC + "MajCompetenceEmployes";
export const GET_COMPETENCE_EMPLOYES_SAISIE_RAPIDE = FC + "LoadCompetenceEmployesSaisieRapide";
export const ADD_COMPETENCE_EMPLOYES_SAISIE_RAPIDE = FC + "AddCompetenceEmployesSaisieRapide";
export const MAJ_EXEMPTE_COMPETENCE_EMPLOYE = FC + "MajExempteCompetenceEmploye";

// Competence Acquisitions
export const GET_COMPETENCE_ACQUISITIONS = FC + "LoadCompetenceAcquisitions";
export const MAJ_COMPETENCE_ACQUISITIONS = FC + "MajCompetenceAcquisitions";
export const GET_NEW_COMPETENCE_ACQUISITIONS = FC + "NewCompetenceAcquisitions";

// AffectationEmploye
export const GET_ALL_AFFECTATION_EMPLOYE_BY_EMPLOYE =
  FC + "GetAllAffectationsEmployeByEmploye";
export const ADD_ONE_AFFECTATION_EMPLOYE = FC + "AddOneAffectationEmploye";
export const UPDATE_ONE_AFFECTATION_EMPLOYE =
  FC + "UpdateOneAffectationEmploye";
export const DELETE_MANY_AFFECTATIONS_EMPLOYE =
  FC + "DeleteAffectationsEmploye";
export const MAJ_EMPLOYE_AFFECTATIONS = FC + "MajAffectationEmploye";

// CategorieEmploye
export const GET_ALL_CATEGORIE_EMPLOYE = FC + "GetAllCategoriesEmployes";

// EmployeCategorie
export const GET_ALL_EMPLOYE_CATEGORIE_BY_EMPLOYE =
  FC + "GetAllEmployesCategoriesByEmploye";
export const ADD_ONE_EMPLOYE_CATEGORIE = FC + "AddOneEmployeCategorie";
export const UPDATE_ONE_EMPLOYE_CATEGORIE = FC + "UpdateOneEmployeCategorie";
export const DELETE_MANY_EMPLOYES_CATEGORIES = FC + "DeleteEmployesCategories";
export const MAJ_EMPLOYE_CATEGORIES = FC + "MajEmployeCategories";

// Formation
export const GET_ALL_FORMATION_RECUE_BY_EMPLOYE =
  FC + "GetAllFormationRecuByEmploye";
export const ADD_FORMATION_RECUE_BY_EMPLOYE = FC + "AddFormationRecuByEmploye";
export const UPDATE_FORMATION_RECUE_DATE_INSCRIPTION = FC + "UpdateFormationRecuDateInscription"

// Employés et Contacts Externes
export const GET_EMPLOYE_BY_NO = FC + "LoadEmployeIentificationByNo";
export const GET_EMPLOYE_BY_ID = FC + "LoadEmployeIentificationById";
export const GET_NEW_EMPLOYE = FC + "NewEmployeIentification";
export const ADD_UPDATE_EMPLOYE = FC + "AddUpdateEmployeIentification";
export const DELETE_EMPLOYE = FC + "DeleteEmployeIentification";

// Employés et Contacts Externes - Taux Horaire
export const GET_TAUX_HORAIRE = FC + "LoadTauxHoraire";
export const ADD_TAUX_HORAIRE = FC + "AddTauxHoraire";
export const UPDATE_TAUX_HORAIREE = FC + "UpdateTauxHoraire";
export const DELETE_TAUX_HORAIRE = FC + "DeleteTauxHoraire";
export const UPDATE_IND_HORS_QC_TAUX_HORAIRE = FC + "UpdateIndHorsQuebec";

// Employés et Contacts Externes - Postes Occupés
export const GET_ALL_POSTE_OCCUPE = FC + "GetAllEmployePosteOccupe";
export const ADD_POSTE_OCCUPE = FC + "AddUpdateEmployePosteOccupe";
export const DELETE_POSTE_OCCUPE = FC + "DeleteEmployePosteOccupe";
export const SEARCH_POSTES = FC + "SearchPostes";
export const MAJ_EMPLOYE_POSTES_OCCUPES = FC + "MajEmployePosteOccupes";

// Employés et Contacts Externes - ÉPI
export const GET_ALL_EMPLOYE_EPI = FC + "GetAllEmployeEpi";
export const ADD_EMPLOYE_EPI = FC + "AddEmployeEpi";
export const UPDATE_EMPLOYE_EPI = FC + "UpdateEmployeEpi";
export const DELETE_EMPLOYE_EPI = FC + "DeleteEmployeEpi";
export const MAJ_EMP_EPIS = FC + "MajEmployeEpis"

//Rapport
const RAPPORT = API + "Rapport/";
const UNAUTH_RAPPORT = API + "UnauthenticatedRapport/";
export const RAPPORT_GET_COURRIEL = RAPPORT + "GetCourriel";
export const GET_LOGO_ENTREPRISE = RAPPORT + "GetLogo";
export const UNAUTH_GET_LOGO_ENTREPRISE = UNAUTH_RAPPORT + "GetLogo";

// ProcedeActivite
export const GET_ALL_PROCEDES_ACTIVITES_BY_CODE_SITE =
  FC + "GetAllProcedeActiviteBySite";

// ConfigurationsDuModule
export const GET_CONFIGURATION_MODULE_FC = FC + "GetConfigurationModule";
export const UPDATE_CONFIGURATION_MODULE_FC = FC + "UpdateConfigurationModule";

// FORMATION EN LIGNE--------------------------------------------------------------- //
const FORMATION_EN_LIGNE = API + "FormationEnLigne/";

// FORMATION COMPETENCE RAPPORTS------------------------------------------------------//
const FCR = API + "FormationCompetencesRapports/";
export const FCR_GET_PLAN_FORMATION_DETAIL_REPORT =
  FCR + "GetPlanFormationDetailReport";
export const FCR_GET_FEUILLE_PRESENCE_VEERGE_REPORT =
  FCR + "GetFeuillePresenceViergeReport";
export const FCR_GET_RAPPEL_ACTIVITES_REPORT_CRITERIAS =
  FCR + "GetRappelActivitesReportCriterias";
export const FCR_GET_RAPPEL_ACTIVITES_REPORT = FCR + "GetRappelActivitesReport";
export const FCR_LOAD_FORMATION_ATTESTATION_RESULTATS =
  FCR + "LoadFormatioAttestationResultats";
export const FCR_GET_ATTESTATION_REPORT = FCR + "GetAttestationReport";
export const FCR_GET_FORMATION_RAPPORT_OPTION_POSTES =
  FCR + "GetFormationRapportOptionPostes";
export const FCR_GET_PRESENCES_ACTIVITE_REPORT =
  FCR + "GetPresencesActiviteReport";
export const FCR_GET_EVALUATION_PERFORMANCE_REPORT =
  FCR + "GetEvaluationPerformanceReport";
export const FCR_GET_EXAMEN_CORRIGE_RAPORT = FCR + "GetExamenCorrigeReport";

// Entrainement
const FEL_ENTRAINEMENT = FORMATION_EN_LIGNE + "";
export const FEL_FORMATION = FEL_ENTRAINEMENT + "One";
export const FEL_DOCUMENT = FEL_ENTRAINEMENT + "FormationDocument";
export const FEL_SECTION = FEL_ENTRAINEMENT + "FormationSection";
export const FEL_INSCRIPTION = FEL_ENTRAINEMENT + "FormationInscription";
export const FEL_SELECTION_DE_PERSONNE = FEL_ENTRAINEMENT + "PersonSelect";
export const FEL_ADD_INSCRIPTION_DE_FORMATION =
  FEL_ENTRAINEMENT + "AddFormationInscription";
export const FEL_ADD_EXAMEN_DE_FORMATION =
  FEL_ENTRAINEMENT + "AddFormationExamen";
export const FEL_UPDATE_INFO_EXAMEN_DE_FORMATION =
  FEL_ENTRAINEMENT + "UpdateFormationExamenInfo";
export const FEL_INFO_EXAMEN = FEL_ENTRAINEMENT + "ExamenInfo";
export const FEL_QUESTION_EXAMEN = FEL_ENTRAINEMENT + "ExamenQuestion";
export const FEL_REPONSE_AUX_QUESTIONS_EXAMEN =
  FEL_ENTRAINEMENT + "ExamenQuestionReponse";
export const FEL_QUESTION_EXAMEN_FICHIER_JOINT =
  FEL_ENTRAINEMENT + "ExamenQuestionFichierJoint";
export const FEL_UPDATE_REPONSE_AUX_QUESTIONS_EXAMEN =
  FEL_ENTRAINEMENT + "UpdateExamenQuestionReponse";

export const FEL_RAPPORT_EXAMEN = FEL_ENTRAINEMENT + "ExamReport";
export const FEL_RAPPORT_ATTESTATION =
  FEL_ENTRAINEMENT + "FormationCertificate";
export const FEL_RAPPORT_CALENDRIER_ACTIVITE =
  FEL_ENTRAINEMENT + "FormationDetailReport";

// Entrainement Search
const FEL_SEARCH = FEL_ENTRAINEMENT + "Search/";
export const FEL_FORMATIONS = FEL_SEARCH + "AllToRegisterLogged";
export const FEL_RECEVOIR_FORMATION = FEL_SEARCH + "AllToBeginLogged";
export const FEL_RECEVOIR_MULTI_FORMATION = FEL_SEARCH + "AllToContinueLogged";

// Entrainement Search Criteria
const FEL_CRITERIAS = FEL_SEARCH + "Criterias/";
export const FEL_FETCH_VILLES = FEL_CRITERIAS + "Villes";
export const FEL_FETCH_TYPE_PERSONNES = FEL_CRITERIAS + "PersonTypes";
export const FEL_FETCH_SUPERIEURS_IMMEDIATS =
  FEL_CRITERIAS + "ImmediatSuperiors";
export const FEL_FETCH_POSTES = FEL_CRITERIAS + "Postes";

// Prepare
const FEL_PREPARE = FORMATION_EN_LIGNE + "Edit/";
export const FEL_PREPARE_NEW = FEL_PREPARE + "New";
export const FEL_PREPARE_ADD = FEL_PREPARE + "Add";

// Prepare Criteria
const FEL_PREPARE_CRITERIAS = FEL_PREPARE + "Criterias/";
export const FEL_FECH_CODE_FORMATION = FEL_PREPARE_CRITERIAS + "CodeFormation";

// EMPLOYE -------------------------------------------------------------------------- //
const EMPLOYE = API + "Employe/";
export const EMP_LOGGED = EMPLOYE + "OneLogged";
export const EMP_SUMMARY_LOGGED = EMPLOYE + "SummaryLogged";
export const EMP_SUMMARY_BY_ID = EMPLOYE + "SummaryByIdEmploye";
export const EMP_CRITERIA_SEARCH = EMPLOYE + "CriteriaSearch";
export const EMP_GET_TYPES = EMPLOYE + "EmployeTypes";
export const EMP_IMM_SUP = EMPLOYE + "ImmediateSuperiors";
export const EMP_EMP_BY = EMPLOYE + "EmployedBys";
export const EMP_ONE = EMPLOYE + "One";
export const EMP_ONE_BY_ID = EMPLOYE + "OneById";
export const EMP_MANY_QUICK_INFO_BY_CODE =
  EMPLOYE + "ManyQuickInfoByEmployeCodes";
export const EMP_MANY_QUICK_INFO_BY_ID = EMPLOYE + "ManyQuickInfoByIds";
export const EMP_NO = EMPLOYE + "NoEmployes";
export const EMP_TYPES_SITE = EMPLOYE + "EmployeTypesSite";
export const EMP_AUTRES_IDENTIFIANT_1 = EMPLOYE + "AutresIdentifiant1";
export const EMP_AUTRES_IDENTIFIANT_2 = EMPLOYE + "AutresIdentifiant2";
export const EMP_AUTRES_IDENTIFIANT_3 = EMPLOYE + "AutresIdentifiant3";
export const EMP_CATEGORIE_CONTACT = EMPLOYE + "CategorieContact";
export const EMP_STATUTS = EMPLOYE + "Statuts";
export const EMP_QUART = EMPLOYE + "QuartDeTravail";
export const EMP_EQUIPE = EMPLOYE + "EquipeDeTravail";

export const EMP_SUP_IMMEDIA_EMPL_SITE = EMPLOYE + "SuperieursImmediatEmplSite";
export const EMP_AFFECTATIONS_CRIT_EMPL_SITE =
  EMPLOYE + "AffectationsCritereEmplSite";
export const EMP_CAT_EMPLOYE = EMPLOYE + "CategorieEmploye";
export const EMP_POSTES_EMPL_SITE = EMPLOYE + "PostesEmplSites";

export const EMP_NEW = EMPLOYE + "New";
export const EMP_ADD = EMPLOYE + "Add";
export const EMP_UPDATE = EMPLOYE + "Update";
export const EMP_SEARCH = EMPLOYE + "EmployeSearch";
export const EMP_COURRIELS = EMPLOYE + "Courriels";
export const EMP_PERSONNES_SELECT = EMPLOYE + "PersonnesSelect";
export const EMP_DELETE = EMPLOYE + "Delete";
export const EMP_GET_DUPLIQUER_INFO = EMPLOYE + "GetDupliquerInfo";
export const EMP_DUPLIQUER = EMPLOYE + "Dupliquer";
export const EMP_DUPLIQUER_SECURITE_EMAIL = EMPLOYE + "DupliquerSecuriteEmail";
export const EMP_DESACTIVER_REACTIVER = EMPLOYE + "DesactiverReactiver";
export const EMP_IMAGE_BY_ID = EMPLOYE + "ImageByIdEmploye";
export const EMP_UPDATE_IMAGE = EMPLOYE + "UpdateImageEmploye";

const EMPLOYE_SKILL = API + "CompetenceEmploye/";
export const EMP_SKILL_SEARCH = EMPLOYE_SKILL + "All";
export const EMP_SKILL_ADD = EMPLOYE_SKILL + "Add";
export const EMP_SKILL_UPDATE = EMPLOYE_SKILL + "Update";
export const EMP_SKILL_DELETE = EMPLOYE_SKILL + "Delete";
export const EMP_SKILLS_MAJ = EMPLOYE_SKILL + "MajCompetences";
export const EMP_SKILL_FILTER_LIST = EMPLOYE_SKILL + "GetCompetencesFilterList";


//POSTE ---------------------------------------------------------------------------- //
const POSTES = API + "Poste/";
export const POSTES_EXT_SITES = POSTES + "PostesExternesSites";
export const POSTES_GET_CRITERES = POSTES + "GetPosteCriteres";
export const POSTES_LOAD_RESULTAT = POSTES + "LoadPosteResultat";

//Produit ---------------------------------------------------------------------------//
export const PRODUIT = API + "Produit/";
export const PRODUIT_SEARCH = PRODUIT + "Search";
export const PRODUIT_GET_COUNT = PRODUIT + "GetCount";
export const PRODUIT_GET_ONE_BY_CODE = PRODUIT + "GetOneByCode";

//Alerte ---------------------------------------------------------------------------//
const ALERTE = API + "Alerte/";
export const LOAD_ALERTE = ALERTE + "LoadAlerte";
export const ADD_UPDATE_ALERTE = ALERTE + "AddUpdateAlerte";
export const DELETE_ALERTE = ALERTE + "DeleteAlerte";

// LANGUE -------------------------------------------------------------------------- //
export const FETCH_LANGUAGES = API + "Languages/Values";
export const FETCH_LANGUAGES_DISPO = API + "Languages/AllDispos";
export const FETCH_ALL_DISPOS = API + "Languages/AllDispos  ";


//#region Maintenance
const MAINTENANCE = API + "Maintenance/";
export const MAINT_MENU_TREE = MAINTENANCE + "MenuTree";
export const MAINTENANCE_CONTENT_DATA = MAINTENANCE + "GetMaintenanceScreenData";

export const CATEG_MOT_CLE_MAINT = MAINTENANCE + "CategMotCleMaintVM";
export const TYPE_SUPPORT_MAINT = MAINTENANCE + "TypeSupportMaintVM";
export const TYPE_CAUSES_FONDAM = MAINTENANCE + "TypeCauseFondamMaintVM";


// General
const MAINT_GENERAL = MAINTENANCE + "General/";
export const MAINT_GENERAL_CRUD = [];

// La liste des possibles paramètres se trouve dans: \src\Components\Modules\Maintenance\index.jsx
export function maintCrudUrlsFactory(sectionKey) {
  if (!sectionKey) {
    return;
  }

  const sectionKeyCapitalized =
    sectionKey[0].toUpperCase() + sectionKey.substr(1);

  const maintCrud = {
    addOneUrl: MAINT_GENERAL + `Add${sectionKeyCapitalized}`,
    getOneUrl: MAINT_GENERAL + `GetAll${sectionKeyCapitalized}`,
    updateOneUrl: MAINT_GENERAL + `Update${sectionKeyCapitalized}`,
    deleteOneUrl: MAINT_GENERAL + `Delete${sectionKeyCapitalized}`,
  };

  return maintCrud;
}

// MultiLangue Traduction
export const ADD_MAINT_MULTILANGUE_TRADUCTION = MAINT_GENERAL + "AddMaintMultilangueTraduction";
export const FETCH_MAINT_MULTILANGUE_TRADUCTION = MAINT_GENERAL + "GetAllMaintMultilangueTraduction";
export const UPDATE_MAINT_MULTILANGUE_DESCRIPTION_TRADUITE = MAINT_GENERAL + "UpdateDescTraduite";

// Sites Associes
export const ADD_SITE_ASSOCIE = MAINT_GENERAL + "AddSiteAssocie";
export const ADD_SITES_ASSOCIES = MAINT_GENERAL + "AddSitesAssocies";
export const DELETE_SITE_ASSOCIE = MAINT_GENERAL + "DeleteSiteAssocie";
export const DELETE_SITES_ASSOCIES = MAINT_GENERAL + "DeleteSitesAssocies";

//Gestion des types de documents
export const LOAD_WF_MODELES = MAINT_GENERAL + "LoadWfModeles";
export const MAJ_WF_MODELES = MAINT_GENERAL + "MajWfModeles";
//#endregion


// SURVEILLANCE ET MESURAGE
const SM = API + "SurveillanceMesurage/";
export const LOAD_SURVEILLANCE_MESURAGE = SM + "LoadSMResultSurMesRecherche";
export const LOAD_SM_CRITERES = SM + "LoadSMCriteres";
export const LOAD_SM_RESULTAT = SM + "LoadSMResultSurMesEdit";


// MESSAGES/TEXTES DU SYSTÈME ------------------------------------------------------ //
const MESSAGES = API + "Messages/";
const MESSAGES_LOGGED_OUT = API + "MessagesUnauthenticated/";

// ObjetsLangues2 dba
export const FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE =
  MESSAGES + "GetAllObjetsLangues2ByLangue";
export const UPDATE_OBJETS_LANGUES_2 = MESSAGES + "UpdateObjetsLangues2";
export const FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE_LOGGED_OUT =
  MESSAGES_LOGGED_OUT + "GetAllObjetsLangues2ByLangue";

// Messages_Langues dba
export const FETCH_ALL_MESSAGES_LANGUES_BY_LANGUE =
  MESSAGES + "GetAllMessagesLanguesByLangue";

// NON-CONFORMITY MODULE ----------------------------------------------------------- //
const NON_CONFORMITES = API + "NonConformites/";
const NC_SEARCH = NON_CONFORMITES + "Search/";
export const NC_SEARCH_SIMPLE = NC_SEARCH + "Simple";
export const NC_ARRET_ASSIGNATION_SEARCH = NC_SEARCH + "NcArretAssignationSearch";

const NC_SEARCH_CRITERIAS = NC_SEARCH + "Criterias/";
export const FETCH_OTHER_IDS = NC_SEARCH_CRITERIAS + "OtherIds";
export const FETCH_PROCESSES = NC_SEARCH_CRITERIAS + "Processes";
export const FETCH_UNITS = NC_SEARCH_CRITERIAS + "Units";
export const FETCH_COMPONENTS = NC_SEARCH_CRITERIAS + "Components";
export const FETCH_SAMPLES = NC_SEARCH_CRITERIAS + "Samples";
export const FETCH_NC_CRITERES_MULTI_TYPES =
  NC_SEARCH_CRITERIAS + "GetCriteresMultiType";
export const FETCH_TYPES = NC_SEARCH_CRITERIAS + "Types";
export const FETCH_NC_EDIT_TYPES_CRITERE = NC_SEARCH_CRITERIAS + "TypesNcCritereSites";
export const FETCH_NC_SEARCH_VOLETS = NC_SEARCH_CRITERIAS + "Volets";
export const FETCH_SEVERITIES = NC_SEARCH_CRITERIAS + "Severities";
export const FETCH_REPORTED_BYS = NC_SEARCH_CRITERIAS + "ReportedBys";
export const FETCH_REPORTED_BYS_SIGNALEMENT =
  NC_SEARCH_CRITERIAS + "ReportedBysSignalement";
export const FETCH_ACCEPTED_DECLINED_BYS =
  NC_SEARCH_CRITERIAS + "AcceptedDeclinedBys";
export const FETCH_ACCEPTED_DECLINED_BYS_SIGNAL =
  NC_SEARCH_CRITERIAS + "AcceptedDeclinedBysSignalement";
export const FETCH_FIELDS_OF_RENSEIGNEMENTS =
  NC_SEARCH_CRITERIAS + "Renseignements";
export const FETCH_RENSEIGNEMENTS_NO_BON_COMMANDE =
  NC_SEARCH_CRITERIAS + "RenseignementsNoBonCommande";
export const FETCH_RENSEIGNEMENTS_NO_BON_TRAVAIL =
  NC_SEARCH_CRITERIAS + "RenseignementsNoBonTravail";
export const FETCH_RENSEIGNEMENTS_NO_DESSIN =
  NC_SEARCH_CRITERIAS + "RenseignementsNoDessin";
export const FETCH_RENSEIGNEMENTS_NO_SERIE =
  NC_SEARCH_CRITERIAS + "RenseignementsNoSerie";
export const FETCH_RENSEIGNEMENTS_NO_LOT =
  NC_SEARCH_CRITERIAS + "RenseignementsNoLot";
export const FETCH_ETAPES_NC = NC_SEARCH_CRITERIAS + "EtapesNC";
export const FETCH_EMPLOYES_BLESSE =
  NC_SEARCH_CRITERIAS + "EmployesBlessesSignalement";
export const FETCH_EMPLOYES_BLESSE_SIGNALEMENT =
  NC_SEARCH_CRITERIAS + "EmployesBlessesSignalement";
export const FETCH_CLASSES_NC_SIGNALEMENT =
  NC_SEARCH_CRITERIAS + "ClassesNCSignalement";
export const FETCH_PRODUITS = NC_SEARCH_CRITERIAS + "Produits";
export const FETCH_ORIGINES = NC_SEARCH_CRITERIAS + "Origines";
export const FETCH_RAPPORT_PARETO_CRITERES =
  NC_SEARCH_CRITERIAS + "RapportParetoCriteres";
export const FETCH_RAPPORT_ARRET_ASSIGNATION_CRITERES =
  NC_SEARCH_CRITERIAS + "RapportArretAssignationCriteres";

export const FETCH_RENSEIGNEMENT_MULTI_TYPE =
  NC_SEARCH_CRITERIAS + "RenseignementsCriteresMultiType";
export const FETCH_RENSEIGNEMENT_MULTI_INFO =
  NC_SEARCH_CRITERIAS + "RenseignementsCriteresMultiInfo";
export const FETCH_FIELDS_OF_DEROGATION = NC_SEARCH_CRITERIAS + "Derogation"
export const FETCH_DEROGATION_NO_BON_COMMANDE = NC_SEARCH_CRITERIAS + "DerogationNoBonCommande"
export const FETCH_DEROGATION_NO_SERIE = NC_SEARCH_CRITERIAS + "DerogationNoSerie"
export const FETCH_DEROGATION_NO_LOT = NC_SEARCH_CRITERIAS + "DerogationNoLot"
export const FETCH_DEROGATION_NO_BON_TRAVAIL = NC_SEARCH_CRITERIAS + "DerogationNoBonTravail"
export const GET_DERROGATIONS_CRITERES_MULTI_TYPE = NC_SEARCH_CRITERIAS + "GetDerogationsCriteresMultiType"
export const GET_DERROGATIONS_CRITERES_MULTI_INFO = NC_SEARCH_CRITERIAS + "GetDerogationsCriteresMultiInfo"

export const GET_DEMANDE_DEROGATION_REPORT =
  NON_CONFORMITES + "GetDemandeDerogationReport";
export const GET_DEMANDE_RENSEIGNEMENT_REPORT =
  NON_CONFORMITES + "GetDemandeRenseignementReport";
export const GET_ARRET_ASSIGNATION_REPORT =
  NON_CONFORMITES + "GetArretAssignationReport";

const NC_EDIT = NON_CONFORMITES + "Edit/";
export const NC_LOAD = NC_EDIT + "Load";
export const NC_LOAD_RESUME = NC_EDIT + "LoadResume";
export const NC_DEFAULT_LOAD = NC_EDIT + "NewNc";
export const NC_DEFAULT_LOAD_FROM_MODELE = NC_EDIT + "NewNcFromModele";
export const NC_DELETE = NC_EDIT + "DeleteNC";
export const NC_LOAD_PERSO = NC_EDIT + "LoadPerso";
export const NC_DUPLIQUER_PERSO = NC_EDIT + "DupliquerPerso";
export const NC_LOAD_ECRAN_MENU = NC_EDIT + "LoadEcranMenu";
export const NC_UPDATE_ECRAN_MENU = NC_EDIT + "UpddateEcranMenu";
export const NC_LOAD_TYPE_NC = NC_EDIT + "LoadTypeNc";
export const NC_UPDATE_TYPE_NC = NC_EDIT + "UpddateTypeNc";
export const NC_LOAD_LIGNE_PRODUIT = NC_EDIT + "LoadLigneProduit";
export const NC_UPDATE_LIGNE_PRODUIT = NC_EDIT + "UpdateLigneProduit";
export const NC_LOAD_HEURES_TRAVAILLEES = NC_EDIT + "LoadHeuresTravaillees";
export const NC_UPDATE_HEURES_TRAVAILLEES = NC_EDIT + "UpdateHeuresTravaillees";
export const NC_UPDATE_ADD = NC_EDIT + "UpdateAddNC";
export const NC_LOAD_ANALYSE = NC_EDIT + "LoadAnalyse";
export const NC_UPDATE_ANALYSE = NC_EDIT + "UpdateAnalyse";
export const NC_UPDATE_ANALYSE_CAUSE_IMM_NC =
  NC_EDIT + "UpdateAnalyseCauseImmNC";
export const NC_DELETE_ANALYSE_CAUSE_IMM_NC =
  NC_EDIT + "DeleteAnalyseCauseImmNC";
export const NC_UPDATE_ANALYSE_CAUSE_FOND_NC =
  NC_EDIT + "UpdateAnalyseCauseFondNC";
export const NC_DELETE_ANALYSE_CAUSE_FOND_NC =
  NC_EDIT + "DeleteAnalyseCauseFondNC";
export const NC_UPDATE_ANALYSE_CAUSE_FOND_ANALYSE =
  NC_EDIT + "UpdateAnalyseCauseFondAnalyse";
export const NC_DELETE_ANALYSE_CAUSE_FOND_ANALYSE =
  NC_EDIT + "DeleteAnalyseCauseFondAnalyse";
export const NC_LOAD_ANALYSE_ENQUETE_DETAIL =
  NC_EDIT + "LoadAnalyseEnqueteDetail";
export const NC_UPDATE_ANALYSE_ENQUETE_DETAIL =
  NC_EDIT + "UpdateAnalyseEnqueteDetail";
export const NC_LOAD_ACTION = NC_EDIT + "LoadAction";
export const NC_LOAD_ACTION_BY_CODE_ACT_CORR = NC_EDIT + "LoadActionByActionCorr";
export const GET_NC_COURRIEL = NC_EDIT + "GetNcCourriel";
export const NC_UPDATE_ACTION_ACTION = NC_EDIT + "UpdateActionAction";
export const NC_LOAD_NEW_ACTION_ACTION = NC_EDIT + "NewActionAction";
export const NC_DELETE_ACTION_ACTION = NC_EDIT + "DeleteActionAction";
export const NC_ACTTION_UPDATE_RESUME = NC_EDIT + "UpdateActionResume";
export const NC_MAJ_ACTION_RESUME = NC_EDIT + "MajActionResume";
export const NC_LOAD_NEW_ACTION_RESUME = NC_EDIT + "NewActionResume";
export const NC_ACTTION_DELETE_RESUME = NC_EDIT + "DeleteActionResume";
export const NC_LOAD_NEW_ACTION_SUIVI = NC_EDIT + "NewActionSuivi";
export const NC_ACTION_UPDATE_SUIVI = NC_EDIT + "UpdateActionSuivi";
export const NC_ACTION_DELETE_SUIVI = NC_EDIT + "DeleteActionSuivi";
export const NC_LOAD_NEW_ACTION_COUT = NC_EDIT + "NewActionCout";
export const NC_ACTION_UPDATE_COUT = NC_EDIT + "UpdateActionCout";
export const NC_ACTION_DELETE_COUT = NC_EDIT + "DeleteActionCout";
export const NC_LOAD_NEW_ACTION_CALENDRIER = NC_EDIT + "NewActionCalendrier";
export const NC_ACTION_ADD_CALENDRIER = NC_EDIT + "AddActionCalendrier";
export const NC_ACTION_UPDATE_CALENDRIER = NC_EDIT + "UpdateActionCalendrier";
export const NC_ACTION_DELETE_CALENDRIER = NC_EDIT + "DeleteActionCalendrier";
export const NC_LOAD_COUTS = NC_EDIT + "LoadCouts";
export const NC_LOAD_NEW_COUT = NC_EDIT + "NewCout";
export const NC_UPDATE_COUT = NC_EDIT + "UpdateCout";
export const NC_DELETE_COUT = NC_EDIT + "DeleteCout";
export const NC_LOAD_SIGNALEMENT = NC_EDIT + "LoadSignalement";
export const NC_GET_NEW_SIGNALEMENT = NC_EDIT + "NewSignalement";
export const NC_ADD_SIGNALEMENT = NC_EDIT + "AddSignalement";
export const NC_UPDATE_SIGNALEMENT = NC_EDIT + "UpdateSignalement";
export const NC_LOAD_SIGNALEMENT_ACCPETATION =
  NC_EDIT + "LoadSignalementAcceptation";
export const NC_UPDATE_SIGNALEMENT_ACCPETATION =
  NC_EDIT + "UpdateSignalementAcceptation";
export const NC_CHECK_AUTRE_IDENTIFIANT = NC_EDIT + "CheckAutreIdentifiant";
export const NC_GET_SIGNALEMENT_COURRIEL = NC_EDIT + "GetSignalementCourriel";
export const NC_LOAD_RENSEIGNEMENT = NC_EDIT + "LoadRenseignements";
export const NC_UPDATE_RENSEIGNEMENT = NC_EDIT + "UpdateRenseignements";
export const NC_DUPLIQUER_RENSEIGNEMENT = NC_EDIT + 'DupliquerRenseignements'
export const NC_DELETE_RENSEIGNEMENT = NC_EDIT + 'DeleteRenseignements'
export const NC_GET_RENSEIGNEMENT_COURRIEL =
  NC_EDIT + "GetRenseignementsCourriel";
export const NC_LOAD_DEROGATION = NC_EDIT + "LoadDerogation";
export const NC_UPDATE_DEROGATION = NC_EDIT + "UpdateDerogation";
export const NC_DELETE_DEROGATION = NC_EDIT + 'DeleteDerogation'
export const NC_GET_DEROGATION_COURRIEL = NC_EDIT + "GetDerogationCourriel";
export const NC_LOAD_DEROGATION_APPROBATION =
  NC_EDIT + "LoadDerogationApprobation";
export const NC_UPDATE_DEROGATION_APPROBATION =
  NC_EDIT + "UpdateDerogationApprobation";
export const NC_GET_HYPERLIENS_EXIST = NC_EDIT + "GetHyperliensExist";
export const NC_DUPLIQUER = NC_EDIT + "DupliquerNC";
export const DEROG_DUPLIQUER = NC_EDIT + "DupliquerDerogation";
export const NC_LOAD_INCIDENTS_SOINS = NC_EDIT + "LoadIncidentsSoins";
export const NC_UPDATE_INCIDENTS_SOINS = NC_EDIT + "UpdateIncidentsSoins";
export const NC_UPDATE_INCIDENTS_SOINS_STATUT =
  NC_EDIT + "UpdateIncidentsSoinsStatut";
export const NC_UPDATE_INCIDENTS_SOINS_PREMIER_SOIN =
  NC_EDIT + "UpdateIncidentsSoinsPremierSoin";
export const NC_UPDATE_INCIDENTS_SOINS_PARTIE_CORPS_TOUCHES =
  NC_EDIT + "UpdateIncidentsSoinsPartieCorpsTouches";
export const NC_DELETE_INCIDENTS_SOINS_PARTIE_CORPS_TOUCHES =
  NC_EDIT + "DeleteIncidentsSoinsPartieCorpsTouches";
export const NC_MAJ_INCIDENTS_SOINS_DOMMAGES =
  NC_EDIT + "MajIncidentsSoinsDommages";
export const NC_MAJ_INCIDENTS_SOINS_SUIVI_MEDICAL =
  NC_EDIT + "MajIncidentsSoinsSuiviMedical";
export const NC_MAJ_INCIDENTS_SOINS_ARRETS_TRAVAIL =
  NC_EDIT + "MajIncidentsSoinsArretEtAssignation";
export const NC_MAJ_INCIDENTS_SOINS_ASSIGNATION_TEMP =
  NC_EDIT + "MajIncidentsSoinsAssignationTemp";
export const NC_MAJ_INCIDENTS_SOINS_COUTS = NC_EDIT + "MajIncidentsSoinsCout";
export const NC_MAJ_INCIDENTS_SOINS_COMMENTAIRES =
  NC_EDIT + "MajIncidentsSoinsCommentaire";
export const NC_UPDATE_INCIDENTS_SOINS_DOMMAGES_AUTRES =
  NC_EDIT + "UpdateIncidentsSoinsDommagesAutres";
export const NC_UPDATE_INCIDENTS_SOINS_SUIVI_MEDICAL =
  NC_EDIT + "UpdateIncidentsSoinsSuiviMedical";
export const NC_DELETE_INCIDENTS_SOINS_SUIVI_MEDICAL =
  NC_EDIT + "DeleteIncidentsSoinsSuiviMedical";
export const NC_UPDATE_INCIDENTS_SOINS_ARRET_ET_ASSIGNATION =
  NC_EDIT + "UpdateIncidentsSoinsArretEtAssignation";
export const NC_DELETE_INCIDENTS_SOINS_ARRET_ET_ASSIGNATION =
  NC_EDIT + "DeleteIncidentsSoinsArretEtAssignation";
export const NC_UPDATE_INCIDENTS_SOINS_ASSIGNATION_TEMP =
  NC_EDIT + "UpdateIncidentsSoinsAssignationTemp";
export const NC_DELETE_INCIDENTS_SOINS_ASSIGNATION_TEMP =
  NC_EDIT + "DeleteIncidentsSoinsAssignationTemp";
export const NC_UPDATE_INCIDENTS_SOINS_COUT =
  NC_EDIT + "UpdateIncidentsSoinsCout";
export const NC_DELETE_INCIDENTS_SOINS_COUT =
  NC_EDIT + "DeleteIncidentsSoinsCout";
export const NC_UPDATE_INCIDENTS_SOINS_COMMENTAIRE =
  NC_EDIT + "UpdateIncidentsSoinsCommentaire";
export const NC_DELETE_INCIDENTS_SOINS_COMMENTAIRE =
  NC_EDIT + "DeleteIncidentsSoinsCommentaire";
export const NC_LOAD_CONFIG_SYNCHRO = NC_EDIT + "LoadConfigSynchro";
export const NC_UPDATE_CONFIG_SYNCHRO_DSN = NC_EDIT + "UpdateConfigSynchroDsn";
export const NC_UPDATE_CONFIG_SYNCHRO_TYPE_NC =
  NC_EDIT + "UpdateConfigSynchroTypeNc";
export const NC_UPDATE_CONFIG_SYNCHRO_TYPE_DETAILS =
  NC_EDIT + "UpdateConfigSynchroTypeDetails";
export const NC_LOAD_CONFIG_NC = NC_EDIT + "LoadConfigNc";
export const NC_UPDATE_CONFIG_NC = NC_EDIT + "UpdateConfigNc";
export const NC_LOAD_CONFIG_ALERTE = NC_EDIT + "LoadConfigAlerte";
export const NC_LOAD_CONFIG_HEURES_TRAVAILLEES =
  NC_EDIT + "LoadConfigHeuresTravaillees";
export const NC_UPDATE_CONFIG_HEURES_TRAVAILLEES =
  NC_EDIT + "UpdateConfigHeuresTravaillees";
export const NC_LOAD_CHAMP_NC_SYNCHRO = NC_EDIT + "LoadChampNcSynchro";
export const ASSOCIER_NC = NC_EDIT + "AssocierNC";
export const DISSOCIER_NC = NC_EDIT + "DissocierNc";
export const GET_TYPE_NC_BY_CODE = NC_EDIT + "GetTypeNCByCode";
export const LOAD_COUTS_NC = NC_EDIT + "LoadCouts";
export const MAJ_COUTS_NC = NC_EDIT + "MajCouts";
export const MAJ_ACTION_COUTS_NC = NC_EDIT + "MajActionCouts";
export const NC_ADD_NC_FROM_MODELE = NC_EDIT + "AddNcFromModele";

const NC_EDIT_CRITERIAS = NC_EDIT + "Criterias/";
export const FETCH_NC_EDIT_PROCESSES = NC_EDIT_CRITERIAS + "Processes";
export const FETCH_NC_EDIT_PROCESSES_BY_SITE =
  NC_EDIT_CRITERIAS + "ProcessesBySite";
export const FETCH_NC_EDIT_UNITS = NC_EDIT_CRITERIAS + "Units";
export const FETCH_NC_EDIT_COMPONENTS = NC_EDIT_CRITERIAS + "Components";
export const FETCH_NC_EDIT_SAMPLES = NC_EDIT_CRITERIAS + "Samples";
export const FETCH_NC_EDIT_TYPES = NC_EDIT_CRITERIAS + "Types";
export const FETCH_NC_EDIT_SEVERITIES = NC_EDIT_CRITERIAS + "Severities";
export const FETCH_NC_EDIT_CLASS = NC_EDIT_CRITERIAS + "Classifications";
export const FETCH_NC_EDIT_UNITE_MESURE = NC_EDIT_CRITERIAS + "UniteMesure";
export const FETCH_NC_EDIT_CATEGORIE_COUT = NC_EDIT_CRITERIAS + "CategorieCout";
export const FETCH_NC_EDIT_STEPS = NC_EDIT_CRITERIAS + "Steps";
export const FETCH_NC_EDIT_PRODUCTS = NC_EDIT_CRITERIAS + "Products";
export const FETCH_NC_EDIT_SERIAL_NUMBERS = NC_EDIT_CRITERIAS + "SerialNumbers";
export const FETCH_NC_EDIT_LOT_NUMBERS = NC_EDIT_CRITERIAS + "LotNumbers";
export const FETCH_NC_EDIT_ORIGINS = NC_EDIT_CRITERIAS + "Origins";
export const FETCH_NC_EDIT_WORK_ORDERS = NC_EDIT_CRITERIAS + "WorkOrders";
export const FETCH_NC_EDIT_PART_NUMBERS = NC_EDIT_CRITERIAS + "PartNumbers";
export const FETCH_NC_EDIT_WORK_SHIFTS = NC_EDIT_CRITERIAS + "WorkShifts";
export const FETCH_NC_EDIT_WORK_TEAMS = NC_EDIT_CRITERIAS + "WorkTeams";
export const FETCH_NC_EDIT_PROBABILITIES = NC_EDIT_CRITERIAS + "Probabilities";
export const FETCH_NC_EDIT_NO_BDCS = NC_EDIT_CRITERIAS + "NoBDCs";
export const FETCH_NC_EDIT_NO_DESSINS = NC_EDIT_CRITERIAS + "NoDessins";
export const FETCH_NC_EDIT_POSTES = NC_EDIT_CRITERIAS + "Postes";
export const FETCH_NC_EDIT_TACHES = NC_EDIT_CRITERIAS + "Taches";
export const FETCH_NC_EDIT_TACHES_BY_SITES = NC_EDIT_CRITERIAS + "TachesBySite";
export const FETCH_NC_EDIT_TYPES_RISQUES = NC_EDIT_CRITERIAS + "TypesRisques";
export const FETCH_NC_EDIT_RISQUES = NC_EDIT_CRITERIAS + "Risques";
export const FETCH_NC_ASPECTS_RISQUE = NC_EDIT_CRITERIAS + "AspectsRisque";
export const FETCH_NC_GRAVITES = NC_EDIT_CRITERIAS + "Gravites";
export const FETCH_NC_ELEMENTS_AUDIT = NC_EDIT_CRITERIAS + "ElementsAudit";
export const FETCH_NC_ELEMENTS_INSPECTION =
  NC_EDIT_CRITERIAS + "ElementsInspecte";
export const GET_NC_ELEMENTS_INSPECTION =
  NC_EDIT_CRITERIAS + "GetElementsInspection";
export const FETCH_NC_PRODUIT_NOM = NC_EDIT_CRITERIAS + "ProduitNom";
export const FETCH_NC_LISTE_CHOIX_PERS_DETAIL =
  NC_EDIT_CRITERIAS + "GetListeChoixPersoDetail";
export const FETCH_NC_EQUIPEMENTS = NC_EDIT_CRITERIAS + "Equipements";
export const FETCH_NC_IMPACTS_EASM = NC_EDIT_CRITERIAS + "ImpactsEASM";
export const FETCH_NC_CAUSE_IMMEDIATE = NC_EDIT_CRITERIAS + "CauseImmediate";
export const FETCH_NC_TYPE_CAUSE_FONDAM = NC_EDIT_CRITERIAS + "TypeCauseFondam";
export const FETCH_NC_CAUSE_FONDAMENTALE =
  NC_EDIT_CRITERIAS + "CauseFondamentale";
export const FETCH_NC_LISTE1 = NC_EDIT_CRITERIAS + "ListeChoix1";
export const FETCH_NC_CODE_DECISION = NC_EDIT_CRITERIAS + "CodeDecision";
export const FETCH_NC_TYPE_NON_CONFORMITE =
  NC_EDIT_CRITERIAS + "TypeNonConformite";
export const FETCH_NC_ELEMENTS_INSP = NC_EDIT_CRITERIAS + "ElementsInspecte";
export const FETCH_NC_EDIT_CRITERIAS_IDENTIFIANT2 =
  NC_EDIT_CRITERIAS + "Identifiant2";
export const FETCH_NC_EDIT_IDENTIFIANT2_ACTION =
  NC_EDIT_CRITERIAS + "Identifiant2Action";
export const NC_GET_REFERENCE = NC_EDIT_CRITERIAS + "GetNCReference";
export const NC_GET_STATUT_ACCIDENT = NC_EDIT_CRITERIAS + "GetStatutAccident";
export const NC_GET_DISPOSITION = NC_EDIT_CRITERIAS + "Disposition";
export const NC_GET_RESPONSABILITE = NC_EDIT_CRITERIAS + "Responsabilite";
export const NC_GET_TYPE_PRODUIT_DEVERSE =
  NC_EDIT_CRITERIAS + "TypeProduitDeverse";
export const NC_GET_LISTE_CHOIX = NC_EDIT_CRITERIAS + "ListeChoix";
export const NC_GET_SYNCHRO_CHAMPS = NC_EDIT_CRITERIAS + "NcSynchroChamps";

const NC_ACTION = NON_CONFORMITES + "Actions/";
const NC_ACTION_EDIT = NC_ACTION + "Edit/";
const NC_ACTION_SEARCH = NC_ACTION + "Search/";
const NC_ACTION_SEARCH_CRITERIAS = NC_ACTION_SEARCH + "Criterias/";

export const NC_ACTION_SEARCH_SIMPLE = NC_ACTION_SEARCH + "Simple";

export const FETCH_NC_ACTION_AUTRE_IDENTIFIANTS = NC_ACTION_SEARCH_CRITERIAS + "OtherIdentifiers";
export const FETCH_NC_ACTION_CUST_AGREEMENTS = NC_ACTION_SEARCH_CRITERIAS + "CustomerAgreements";
export const FETCH_NC_ACTION_APPROVED_BYS = NC_ACTION_SEARCH_CRITERIAS + "ApprovedBys";
export const FETCH_NC_ACTION_EFFICIENCY_VERIFIED_BYS = NC_ACTION_SEARCH_CRITERIAS + "EfficiencyVerifiedBys";
export const FETCH_NC_ACTION_IMPLEMENTED_BYS = NC_ACTION_SEARCH_CRITERIAS + "ImplementedBys";
export const FETCH_NC_ACTION_INVOLVED_PERSONS = NC_ACTION_SEARCH_CRITERIAS + "InvolvedPersons";
export const FETCH_NC_ACTION_MEETINGS = NC_ACTION_SEARCH_CRITERIAS + "Meetings";
export const FETCH_NC_ACTION_ORIGINS = NC_ACTION_SEARCH_CRITERIAS + "Origins";
export const FETCH_NC_ACTION_PROCESS_TYPES = NC_ACTION_SEARCH_CRITERIAS + "ProcessTypes";
export const FETCH_NC_ACTION_REQUESTED_BYS = NC_ACTION_SEARCH_CRITERIAS + "RequestedBys";
export const FETCH_NC_ACTION_RISKS_REVIEWED_BYS = NC_ACTION_SEARCH_CRITERIAS + "RisksReviewedBys";
export const FETCH_NC_ACTION_SUPPLIERS = NC_ACTION_SEARCH_CRITERIAS + "Suppliers";
export const FETCH_NC_ACTION_TYPES = NC_ACTION_SEARCH_CRITERIAS + "Types";
export const FETCH_NC_ACTION_VOLETS = NC_ACTION_SEARCH_CRITERIAS + "Volets";

export const FETCH_NC_ACTION_EDIT_RESUMES = NC_ACTION_EDIT + "LoadAll";

//NonConformances
export const NC_CON_AE_CRITERIAS = NC_ACTION_EDIT + "Criterias/";
export const NC_CON_AE_NUMEROS_PROC = NC_CON_AE_CRITERIAS + "NumerosProcedure";
export const FETCH_NC_CON_PREVIOUS_ACTIONS =
  NC_CON_AE_CRITERIAS + "PreviousActions";
export const FETCH_NC_CON_AE_CAUSES = NC_CON_AE_CRITERIAS + "Causes";
export const FETCH_NC_CON_AE_AGREEMENT_CUSTOMERS =
  NC_CON_AE_CRITERIAS + "AgreementCustomers";
export const FETCH_NC_CON_AE_PROJECTS = NC_CON_AE_CRITERIAS + "Projects";
export const FETCH_NC_CON_AE_PROCESS_TYPES =
  NC_CON_AE_CRITERIAS + "ProcessTypes";


//#region Sites
const SITES = API + "sites/";
const UNAUTH_SITES = API + "UnauthenticatedSites/";
export const FETCH_ALL_SITES = SITES + "All";
export const FETCH_ALL_ACTIVE_SITES = SITES + "AllActive";
export const FETCH_SITES = SITES + "Criterias";
export const FETCH_ALL_INACTIVE_SITES = SITES + "AllInactive";
export const FETCH_SITES_BY_WINDOW_NAME_ACTION = SITES + "CriteriasParEcranDroit"; // était CriteriasByActionLogged
export const FETCH_SITES_BY_WINDOW_NAME = SITES + "CriteriasParEcran";
export const MAKE_SITE_FAVORITE = SITES + "MakeFavorite"; // était CriteriasByActionLogged
export const UNMAKE_SITE_FAVORITE = SITES + "UnmakeFavorite"; // était CriteriasByActionLogged
export const LOAD_SITES_SEC_USAGER_ADMIN = SITES + "GetSitesUsagerAdmin";
export const ADD_ADMINISTRATEUR_SITES = SITES + "AddAdministrateurSites";
export const DELETE_ADMINISTRATEUR_SITES = SITES + "DeleteAdministrateurSites";
export const UNAUTH_FETCH_ALL_ACTIVE_SITES = UNAUTH_SITES + "AllActive";
export const UNMODIFIABLE_SITES = SITES + 'UnmodifiableSites';
//#endregion


// Inspection  ---------------------------------------------------------------------//
const INSPECTION = API + "Inspection/";
export const FETCH_INSPECTION_DETAIL_GENERALE =
  INSPECTION + "LoadDetailGenerale";
export const UPDATE_INSPECTION_DETAIL_GENERALE =
  INSPECTION + "UpdateDetailGenerale";
export const FETCH_INSPECTION_DETAIL_GROUPS = INSPECTION + "LoadDetailGroupes";
export const FETCH_INSPECTION_DETAIL_GROUP = INSPECTION + "GetDetailGroupe";
export const UPDATE_INSPECTION_DETAIL_GROUP =
  INSPECTION + "UpdateDetailGroupes";
export const FETCH_INSPECTION_DETAIL_ELEMENTS =
  INSPECTION + "LoadDetailElements";
export const FETCH_INSPECTION_DETAIL_GROUP_ELEMENTS =
  INSPECTION + "LoadDetailGroupeElements";
export const UPDATE_INSPECTION_DETAIL_GROUP_ELEMENT =
  INSPECTION + "UpdateDetailGroupeElement";
export const UPDATE_INSPECTION_DETAIL_GROUP_ELEMENTS =
  INSPECTION + "UpdateDetailGroupeElements";
export const FETCH_AUTRE_IDENTIFIANT = INSPECTION + "GetAutreIdentifiant";
export const FETCH_CRITERES_MULTI_TYPES = INSPECTION + "GetCriteresMultiType";
export const FETCH_CRITERES_MULTI_INFOS = INSPECTION + "GetCriteresMultiInfo";
export const SEND_INSPECTION_SEARCH_DETAIL = INSPECTION + "SearchDetail";
export const UPDATE_INSPECTION_SEARCH_FICHES = INSPECTION + "SearchFiches";
export const FETCH_INSPECTION_FICHE_GENERALE = INSPECTION + "GetFicheGenerale";
export const FETCH_INSPECTION_TYPE_INSPECTION =
  INSPECTION + "GetTypeInspection";
export const UPDATE_INSPECTION_FICHE_GENERALE =
  INSPECTION + "AddUpdateFicheGenerale";
export const DELETE_INSPECTION_FICHE_GENERALE =
  INSPECTION + "DeleteFicheGenerale";
export const FETCH_INSPECTION_FICHE_ELEMENTS = INSPECTION + "LoadFicheElements";
export const UPDATE_INSPECTION_FICHE_ELEMENTS =
  INSPECTION + "AddUpdateFicheElements";
export const DUPLICATE_FICHE_ELEMENT = INSPECTION + "DuplicateFicheElement";
export const FETCH_INSPECTION_SELECTION_ELEMENT_STD =
  INSPECTION + "LoadSelectionElementStd";
export const FETCH_INSPECTION_ELEMENT_STD = INSPECTION + "LoadElementStd";
export const UPDATE_INSPECTION_ELEMENT_STD = INSPECTION + "AddUpdateElementStd";
export const DELETE_INSPECTION_ELEMENT_STD = INSPECTION + "DeleteElementStd";
export const FETCH_INSPECTION_FICHE_CALENDRIERS =
  INSPECTION + "LoadFicheCalendriers";
export const FETCH_INSPECTION_NEW_FICHE_CALENDRIERS =
  INSPECTION + "NewFicheCalendrier";
export const ADD_INSPECTION_FICHE_CALENDRIERS =
  INSPECTION + "AddFicheCalendrier";
export const UPDATE_INSPECTION_FICHE_CALENDRIERS =
  INSPECTION + "UpdateFicheCalendrier";
export const DELETE_INSPECTION_FICHE_CALENDRIERS =
  INSPECTION + "DeleteFicheCalendrier";
export const FETCH_INSPECTION_FICHE_RESULTATS =
  INSPECTION + "LoadFicheResultats";
export const INSPECTION_LOAD_CALENDRIER = INSPECTION + "LoadCalendrier";
export const INSPECTION_ADD_FICHE_RESULTAT = INSPECTION + "AddFicheResultat";
export const INSPECTION_LOAD_INSPECTEURS = INSPECTION + "LoadInspecteurs";

// Audits Inpection ---------------------------------------------------------------//
const CALENDRIERFREQUENCE = API + "CalendrierFrequence/";
export const LOAD_FREQ_CALENDRIER = CALENDRIERFREQUENCE + "LoadFreqCalendrier";
export const LOAD_CALENDRIER = CALENDRIERFREQUENCE + "LoadCalendrier";
export const LOAD_PERSONNE_SELECT = CALENDRIERFREQUENCE + "LoadPersonneSelect";

// Audits Inpection ---------------------------------------------------------------//
const AUDITS_INPECTIONS = API + "AuditsInpections/";
export const FETCH_AUDITS_CRITERES_MULTI_TYPES =
  AUDITS_INPECTIONS + "GetCriteresMultiType";
export const FETCH_AUDITS_CRITERES_MULTI_INFOS =
  AUDITS_INPECTIONS + "GetCriteresMultiInfo";
export const SEND_AUDITS_INSPECTION_SEARCH_DETAIL =
  AUDITS_INPECTIONS + "AuditsSearchDetail";
export const GET_AUDITS_INFO_GEN = AUDITS_INPECTIONS + "GetAuditsInfoGen";
export const ADD_AUDITS_INFO_GEN = AUDITS_INPECTIONS + "AddAuditsInfoGen";
export const UPDATE_AUDITS_INFO_GEN = AUDITS_INPECTIONS + "UpdateAuditsInfoGen";
export const DELETE_AUDITS_INFO_GEN = AUDITS_INPECTIONS + "DeleteAuditsInfoGen";
export const LOAD_TYPES_AUDITS = AUDITS_INPECTIONS + "LoadTypesAudits";
export const LOAD_ENTITE_AUDITEES = AUDITS_INPECTIONS + "LoadEntiteAuditees";
export const GET_AUDITS_PARTIE_PRENANTE =
  AUDITS_INPECTIONS + "GetAuditsPartiePrenante";
export const UPDATE_AUDITS_PARTIE_PRENANTE =
  AUDITS_INPECTIONS + "UpdateAuditsPartiePrenante";
export const LOAD_AUDIT_EQUIPE_PROVENANCES =
  AUDITS_INPECTIONS + "LoadAuditEquipeProvenances";
export const LOAD_AUDIT_EQUIPE_ROLES =
  AUDITS_INPECTIONS + "LoadAuditEquipeRoles";
export const GET_AUDITS_INFORMATION_VISEE =
  AUDITS_INPECTIONS + "GetAuditsInformationVisee";
export const UPDATE_AUDITS_INFORMATION_VISEE =
  AUDITS_INPECTIONS + "UpdateAuditsInformationVisee";
export const LOAD_AUDIT_NORMES = AUDITS_INPECTIONS + "LoadAuditNormes";
export const LOAD_AUDIT_ARTICLES = AUDITS_INPECTIONS + "LoadAuditArticles";
export const LOAD_AUDIT_ELEMENT_AUDITABLES =
  AUDITS_INPECTIONS + "LoadAuditElementAuditables";
export const LOAD_AUDITS_QUESTIONS = AUDITS_INPECTIONS + "LoadAuditsQuestions";
export const UPDATE_AUDITS_QUESTIONS =
  AUDITS_INPECTIONS + "UpdateAuditsQuestions";
export const LOAD_AUDITS_SAISIE_QUESTIONS =
  AUDITS_INPECTIONS + "LoadAuditsSaisieQuestions";
export const LOAD_AUDITS_BANQUE_QUESTIONS =
  AUDITS_INPECTIONS + "LoadAuditsBanqueQuestions";
export const ADD_AUDITS_BANQUE_QUESTION =
  AUDITS_INPECTIONS + "AddAuditsBanqueQuestion";
export const UPDATE_AUDITS_BANQUE_QUESTION =
  AUDITS_INPECTIONS + "UpdateAuditsBanqueQuestion";
export const DELETE_AUDITS_BANQUE_QUESTION =
  AUDITS_INPECTIONS + "DeleteAuditsBanqueQuestion";
export const ADD_AUDITS_BANQUE_QUESTION_VERSION =
  AUDITS_INPECTIONS + "AddAuditsBanqueQuestionVersion";
export const UPDATE_AUDITS_BANQUE_QUESTION_VERSION =
  AUDITS_INPECTIONS + "UpdateAuditsBanqueQuestionVersion";
export const DELETE_AUDITS_BANQUE_QUESTION_VERSION =
  AUDITS_INPECTIONS + "DeleteAuditsBanqueQuestionVersion";
export const ADD_AUDITS_BANQUE_ARTICLE =
  AUDITS_INPECTIONS + "AddAuditsBanqueQuestionArticle";
export const UPDATE_AUDITS_BANQUE_ARTICLE =
  AUDITS_INPECTIONS + "UpdateAuditsBanqueQuestionArticle";
export const DELETE_AUDITS_BANQUE_ARTICLE =
  AUDITS_INPECTIONS + "DeleteAuditsBanqueQuestionArticle";
export const LOAD_AUDITS_QUESTION_PERSONNES =
  AUDITS_INPECTIONS + "LoadAuditsQuestionPersonnes";
export const UPDATE_AUDITS_QUESTION_PERSONNES =
  AUDITS_INPECTIONS + "UpdateAuditsQuestionPersonnes";
export const LOAD_AUDITS_PLANIFICATION =
  AUDITS_INPECTIONS + "LoadAuditsPlanification";
export const UPDATE_AUDITS_PLANIFICATION =
  AUDITS_INPECTIONS + "UpdateAuditsPlanification";
export const LOAD_AUDITS_CALENDRIERS =
  AUDITS_INPECTIONS + "LoadAuditsCalendriers";
export const NEW_AUDITS_CALENDRIER = AUDITS_INPECTIONS + "NewAuditsCalendrier";
export const ADD_AUDITS_CALENDRIER = AUDITS_INPECTIONS + "AddAuditsCalendrier";
export const UPDATE_AUDITS_CALENDRIER =
  AUDITS_INPECTIONS + "UpdateAuditsCalendrier";
export const DELETE_AUDITS_CALENDRIER =
  AUDITS_INPECTIONS + "DeleteAuditsCalendrier";

// Audits Inpection ---------------------------------------------------------------//
const AUDITS_INPECTIONS_RAPPORTS = API + "AuditsInpectionsRapports/";
export const FETCH_FORMULAIRE_INSPECTION_RAPPORT =
  AUDITS_INPECTIONS_RAPPORTS + "GetFormulaireInspectionReport";

// HOME --------------------------------------------------------------------------- //
export const FETCH_EMPLOYE_IMAGE = API + "Employe/Image";
export const FETCH_EMPLOYE_IMAGE_BY_ID_EMPLOYE =
  API + "Employe/ImageByIdEmploye";
export const FETCH_EMPLOYE_SUMMARY = API + "Employe/Summary";
export const FETCH_EMPLOYE_SUMMARY_BY_ID_EMPLOYE =
  API + "Employe/SummaryByIdEmploye";
export const FETCH_KM_TODO_ALL = API + "KM/Todo/All";
export const FETCH_KM_NEWS_ALL = API + "KM/News/All";
export const FETCH_KM_REQUESTS_ALL = API + "KM/Request/All";
export const FETCH_KM_REQUESTS_ALL_CODE = API + "KM/Request/AllCode";
export const FETCH_ALL_SLIDER_ITEM = API + "KM/Knowledge/Object/RecentsLogged";
export const FETCH_REQUEST_SETCHOICE = API + "KM/Request/SetChoice";

// KNOWLEDGE -------------------------------------------------------------------------- //
const BALISE = "Balise/";
const CRITERIA = "Criteria/";
const FILTER = "Filter/";
const GENERAL = "General/";
const KM = "KM/";
const KM_KNOWLEDGE = KM + "Knowledge/";
const LINK = "Link/";
const OBJECT = "Object/";
const REQUEST = "Request/";
const UNIVERS = "Univers/";

// Balise
export const GET_ALL_BALISES_BY_DOSSIER =
  API + KM_KNOWLEDGE + BALISE + "AllByDossier";
export const GET_ALL_BALISES_DISTINCT =
  API + KM_KNOWLEDGE + BALISE + "GetAllDistinct";
export const ADD_ONE_BALISE = API + KM_KNOWLEDGE + BALISE + "Add";
export const DELETE_ONE_BALISE = API + KM_KNOWLEDGE + BALISE + "Delete";

// Document de Connaissance
const DOCUMENT_CONNAISSANCE = API + KM_KNOWLEDGE + OBJECT + "Document/";
export const GET_DOCUMENT_CONNAISSANCE =
  DOCUMENT_HOST + DOCUMENT_CONNAISSANCE + "Get";
export const ADD_DOCUMENT_CONNAISSANCE = DOCUMENT_CONNAISSANCE + "Add";
export const DELETE_DOCUMENT_CONNAISSANCE =
  DOCUMENT_HOST + DOCUMENT_CONNAISSANCE + "Delete";
export const GET_DOCUMENT_CONNAISSANCE_MIME_TYPE =
  DOCUMENT_CONNAISSANCE + "GetMimeType";
export const GET_DOCUMENT_CONNAISSANCE_INFO =
  DOCUMENT_CONNAISSANCE + "GetDocInfo";
export const GET_DOCUMENT_CONNAISSANCE_MANAGE =
  DOCUMENT_CONNAISSANCE + "GetDocManage";
export const GET_DOC_CREATION_HISTORY =
  DOCUMENT_CONNAISSANCE + "GetCreationHistory";

export const GET_DOCUMENT_CONNAISSANCE_UNAUTH =
  API + "UnauthenticatedDocument/Get";

// KnowledgeObjectFilter
export const GET_FILTERS_ALL_LOGGED =
  API + KM_KNOWLEDGE + OBJECT + FILTER + "AllLogged";
export const DELETE_FILTER_DELETE =
  API + KM_KNOWLEDGE + OBJECT + FILTER + "Delete";
export const POST_FILTER_ADD = API + KM_KNOWLEDGE + OBJECT + FILTER + "Add";

// KnowledgeObjectLink
export const LINK_ALL = API + KM_KNOWLEDGE + OBJECT + LINK + "All";
export const LINK_ADD = API + KM_KNOWLEDGE + OBJECT + LINK + "Add";
export const LINK_DELETE = API + KM_KNOWLEDGE + OBJECT + LINK + "Delete";
export const LINK_REPORT = API + KM_KNOWLEDGE + OBJECT + LINK + "Report";

export const POST_LINK_DELETE = API + KM_KNOWLEDGE + LINK + "Delete";
export const POST_LINK_REPORT = API + KM_KNOWLEDGE + LINK + "Report";

// Criteria
export const GET_FAVORITES =
  API + KM_KNOWLEDGE + OBJECT + CRITERIA + "Favorites";
export const GET_OBJECT_TYPES =
  API + KM_KNOWLEDGE + OBJECT + CRITERIA + "Types";

export const GET_AUTHORS = API + KM_KNOWLEDGE + OBJECT + CRITERIA + "Authors";
export const GET_KM_OBJECT_EMPLOYES =
  API + KM_KNOWLEDGE + OBJECT + CRITERIA + "Employes";
export const GET_OBJECT_PUBLICATION_STATUSES =
  API + KM_KNOWLEDGE + OBJECT + CRITERIA + "PublicationStatus";
export const GET_OBJECT_RESPONSIBLES =
  API + KM_KNOWLEDGE + OBJECT + CRITERIA + "Responsibles";
export const GET_OBJECT_SPECIAL_STATUSES =
  API + KM_KNOWLEDGE + OBJECT + CRITERIA + "SpecialStatuses";

// KnowledgeObject
export const GET_KNOWLEDGE_OBJECT_ONE =
  API + KM_KNOWLEDGE + OBJECT + "OneLogged";
export const GET_KM_OBJECT_LATEST_VERSION_BY_ID =
  API + KM_KNOWLEDGE + OBJECT + "GetLatestVersionById";
export const GET_KM_OBJECT_LATEST_VERSION_BY_COTE =
  API + KM_KNOWLEDGE + OBJECT + "GetLatestVersionByCote";
export const GET_OBJECT_RECENTS = API + KM_KNOWLEDGE + OBJECT + "RecentsLogged";
export const GET_OBJECT_SEARCH_COUNT =
  API + KM_KNOWLEDGE + OBJECT + "SearchCount";
export const GET_OBJECT_SEARCH = API + KM_KNOWLEDGE + OBJECT + "Search";
export const POST_KNOWLEDGE_OBJECT_ADD = API + KM_KNOWLEDGE + OBJECT + "Add";
export const POST_OBJECT_ADDLINK = API + KM_KNOWLEDGE + OBJECT + "AddLnk";
export const POST_KM_OBJECT_NEW_VERSION =
  API + KM_KNOWLEDGE + OBJECT + "AddNewVersion";
export const POST_KM_OBJECT_NEW_VERSION_TEST =
  API + KM_KNOWLEDGE + OBJECT + "AddNewVersiontst";
export const POST_KNOWLEDGE_OBJECT_UPDATE =
  API + KM_KNOWLEDGE + OBJECT + "Update";
export const POST_OBJECT_DELETE = API + KM_KNOWLEDGE + OBJECT + "Delete";
export const KNOWLEDGE_OBJECT_EVALUATE =
  API + KM_KNOWLEDGE + OBJECT + "Evaluate";
export const KNOWLEDGE_OBJECT_DELETE_EVALUATE =
  API + KM_KNOWLEDGE + OBJECT + "DeleteEvaluate";
export const POST_OBJECT_REVALIDATION =
  API + KM_KNOWLEDGE + OBJECT + "Revalidate";
export const KNOWLEDGE_OBJECT_CONFIRM = API + KM_KNOWLEDGE + OBJECT + "Confirm";
export const POST_OBJECT_APPROVE = API + KM_KNOWLEDGE + OBJECT + "Approve";
export const POST_OBJECT_UNAPPROVE = API + KM_KNOWLEDGE + OBJECT + "Unapprove";
export const GET_OBJECT_REQUEST = API + KM_KNOWLEDGE + OBJECT + "Request";
export const GET_KNOWLEDGE_OBJECT_SUBSCRIBERS =
  API + KM_KNOWLEDGE + OBJECT + "Subscribers";
export const GET_KNOWLEDGE_OBJECT_ENDORSERS =
  API + KM_KNOWLEDGE + OBJECT + "Endorsers";
export const GET_KNOWLEDGE_OBJECT_ENDOSSED_BY =
  API + KM_KNOWLEDGE + OBJECT + "EndossedBy";
export const KNOWLEDGE_OBJECT_TYPE_DOC =
  API + KM_KNOWLEDGE + OBJECT + "GetTypeDoc";
export const CONSULTATION_STOP =
  API + KM_KNOWLEDGE + OBJECT + "ConsultationStop";
export const GET_KNOWLEDGE_OBJECT_NOTIFY =
  API + KM_KNOWLEDGE + OBJECT + "Notify";
export const GET_KNOWLEDGE_OBJECT_OPTION_LIST =
  API + KM_KNOWLEDGE + OBJECT + "OptionList";
export const GET_CREATION_HISTORY = API + KM_KNOWLEDGE + OBJECT + "Notify";
export const GET_CONSULTANTS_BY_DOSSIER_ID =
  API + KM_KNOWLEDGE + OBJECT + "GetConsultantsByDossierId";
export const GET_TRUSTED_AGENTS =
  API + KM_KNOWLEDGE + OBJECT + "GetTrustedAgents";
export const GET_ENDORSEMENT_TYPE_COUNTERS =
  API + KM_KNOWLEDGE + OBJECT + "GetEndorsementTypeCounters";

// Bookmark
export const KNOWLEDGE_OBJECT_ALL_BOOKMARK =
  API + KM_KNOWLEDGE + OBJECT + "AllBookmark";
export const KNOWLEDGE_OBJECT_ADD_BOOKMARK =
  API + KM_KNOWLEDGE + OBJECT + "AddBookmark";
export const KNOWLEDGE_OBJECT_UPDATE_BOOKMARK =
  API + KM_KNOWLEDGE + OBJECT + "UpdateBookmark";
export const KNOWLEDGE_OBJECT_DELETE_BOOKMARK =
  API + KM_KNOWLEDGE + OBJECT + "DeleteBookmark";
export const KNOWLEDGE_OBJECT_NOTIFICATION_RAPIDE =
  API + KM_KNOWLEDGE + OBJECT + "NotificationRapide";

export const POST_OBJECT_SUBSCRIBE = API + KM_KNOWLEDGE + OBJECT + "Subscribe";
export const POST_OBJECT_UNSUBSCRIBE =
  API + KM_KNOWLEDGE + OBJECT + "Unsubscribe";

// KnowledgeObjectComment
const KNOWLEDGE_OBJECT_COMMENT = API + KM_KNOWLEDGE + OBJECT + "Comment/";
export const KNOWLEDGE_OBJECT_COMMENT_ALL = KNOWLEDGE_OBJECT_COMMENT + "All";
export const KNOWLEDGE_OBJECT_COMMENT_ADD = KNOWLEDGE_OBJECT_COMMENT + "Add";
export const KNOWLEDGE_OBJECT_COMMENT_FILES_ADD =
  KNOWLEDGE_OBJECT_COMMENT + "AddFiles";
export const KNOWLEDGE_OBJECT_COMMENT_FILES_DELTE =
  KNOWLEDGE_OBJECT_COMMENT + "DeleteFiles";

// General
export const GET_GENERAL_CONSULTATION_UNITS =
  API + KM + GENERAL + "ConsultationUnits";
export const GET_GENERAL_DISPLAYS = API + KM + GENERAL + "Displays";
export const GET_GENERAL_UNIVERS_SORTS = API + KM + GENERAL + "UniversSorts";
export const GET_GENERAL_OBJECT_SORTS = API + KM + GENERAL + "ObjectSorts";
export const GET_GENERAL_ENDORSEMENT_TYPES =
  API + KM + GENERAL + "EndorsementTypes";
export const REQUEST_LIST = API + KM + GENERAL + "RequestList";
export const GET_CONFIG_GEC = API + KM + GENERAL + "GetConfigGec";
export const UPDATE_CONFIG_GEC = API + KM + GENERAL + "UpdateConfigGec";

// Only knowledge
export const POST_KNOWLEDGE_COMMENT_ALL = API + KM_KNOWLEDGE + "CommentAll";
export const POST_KNOWLEDGE_COMMENT_ADD = API + KM_KNOWLEDGE + "CommentAdd";
export const POST_KNOWLEDGE_SHARE = API + KM_KNOWLEDGE + "Share";
// export const POST_KNOWLEDGE_UPDATE_FAV = API + KM_KNOWLEDGE + "UpdateFavorite";
export const POST_KNOWLEDGE_FAVORITE = API + KM_KNOWLEDGE + "Favorite";
export const POST_KNOWLEDGE_UNFAVORITE = API + KM_KNOWLEDGE + "UnFavorite";
export const POST_KNOWLEDGE_SUBSCRIBE = API + KM_KNOWLEDGE + "Subscribe";
export const POST_KNOWLEDGE_UNSUBSCRIBE = API + KM_KNOWLEDGE + "Unsubscribe";

export const GET_KNOWLEDGE_IMAGE = API + KM_KNOWLEDGE + "Image";
export const POST_KNOWLEDGE_UPDATE_IMAGE = API + KM_KNOWLEDGE + "UpdateImage";

//Univers
export const POST_UNIVERS_ADD = API + KM_KNOWLEDGE + UNIVERS + "Add";
export const POST_UNIVERS_DELETE = API + KM_KNOWLEDGE + UNIVERS + "Delete";
export const POST_UNIVERS_UPDATE = API + KM_KNOWLEDGE + UNIVERS + "Update";
export const GET_UNIVERS_FILTER_ALL =
  API + KM_KNOWLEDGE + UNIVERS + FILTER + "All";

export const GET_UNIVERS_SEARCH = API + KM_KNOWLEDGE + UNIVERS + "Search";
export const GET_UNIVERS_SEARCH_COUNT =
  API + KM_KNOWLEDGE + UNIVERS + "SearchCount";
export const GET_UNIVERS_ONE = API + KM_KNOWLEDGE + UNIVERS + "One";
export const GET_UNIVERS_ONE_LOGGED = API + KM_KNOWLEDGE + UNIVERS + "OneLogged";
export const ADD_LNK_UC = API + KM_KNOWLEDGE + UNIVERS + "AddLnkUC";
export const KM_UNIVERS_SEARCH_CONTENT = API + KM_KNOWLEDGE + UNIVERS + "SearchContent";
export const GET_UC_OPTION_LIST = API + KM_KNOWLEDGE + UNIVERS + "OptionList";

// KnowledgeUniversCriteria
export const GET_UNIVERS_CRITERIA_SITES = API + KM_KNOWLEDGE + UNIVERS + CRITERIA + "Sites";
export const GET_UNIVERS_CRITERIA_FAVORITES = API + KM_KNOWLEDGE + UNIVERS + CRITERIA + "Favorites";

// KnowledgeUniversLink
export const GET_ALL_KM_UNIVERS_LINK = API + KM_KNOWLEDGE + UNIVERS + LINK + "All";
export const PUT_MERGE_KM_UNIVERS_LINK = API + KM_KNOWLEDGE + UNIVERS + LINK + "Merge";

// Request
export const GET_ALL_POSTES_RESTRIENT =
  API + KM + REQUEST + "AllPosteRestrient";
export const ADD_REQUEST_BY_CODE_SYSTEME =
  API + KM + REQUEST + "AddByCodeSysteme";

// Fournisseur
const FOURNISSEUR = API + "Fournisseur/";
export const LOAD_FOURNISSEUR_DOCUMENTS_REMPLIR =
  FOURNISSEUR + "LoadRequeteFournDocumentRemplir";
export const GET_ONE_FOURNISSEUR_DOCUMENTS_REMPLIR =
  FOURNISSEUR + "GetRequeteFournDocumentRemplir";
export const UPDATE_REQUETE_FOURN_DOCUMENT_REMPLIR_DOCUMENT =
  FOURNISSEUR + "UpdateRequeteFournDocumentRemplirDocument";

//Planification
const PLANIFICATION = API + "Planification/";
export const GET_LOAD_RESPONSABLE_ACTION_LISTE =
  PLANIFICATION + "LoadResponsableActionListe";
export const POST_SEARCH_ACTIONS = PLANIFICATION + "SearchActions";
export const GET_ACTION = PLANIFICATION + "GetAction";
export const POST_UPDATE_ACTIONS = PLANIFICATION + "UpdateAction";

export const LOAD_CARNET_PREVENTION_PROGRAMMES = PLANIFICATION + "LoadCarnetPreventionProgrammes";
export const LOAD_CARNET_PREVENTION = PLANIFICATION + "LoadCarnetPrevention";

//Performance
const PERF_REVIEW = API + "RevuePerformance/";
export const SEARCH_ACTIONS_PERF_REVIEW = PERF_REVIEW + "SearchActions";
export const LOAD_ACTION_EMPLOYES_LISTE_PERF_REVIEW =
  PERF_REVIEW + "LoadActionEmployesListe";
export const GET_CRITERES_MULTI_TYPE_PERF_REVIEW =
  PERF_REVIEW + "GetCriteresMultiType";
export const GET_ACTION_PERF_REVIEW = PERF_REVIEW + "GetAction";
export const LOAD_ACTION_REUNION_ASSOCIES_PERF_REVIEW =
  PERF_REVIEW + "LoadActionReunionAssocies";
export const ADD_ACTION_PERF_REVIEW = PERF_REVIEW + "AddAction";
export const UPDATE_ACTION_PERF_REVIEW = PERF_REVIEW + "UpdateAction";
export const DELETE_ACTION_PERF_REVIEW = PERF_REVIEW + "DeleteAction";
export const DUPLIQUER_ACTION_PERF_REVIEW = PERF_REVIEW + "DupliquerAction";
export const GET_CRITERES_MULTI_INFO_PERF_REVIEW =
  PERF_REVIEW + "GetCriteresMultiInfo";

//Notifications
const NOTIFICATIONS = API + "Notification/";
export const GET_NOTIFICATIONS = NOTIFICATIONS + "Notifications";
export const SET_NOTIFICATIONS_VUES = NOTIFICATIONS + "SetVu";
export const SET_NOTIFICATION_LU = NOTIFICATIONS + "SetLu";
export const DELETE_NOTIFICATION = NOTIFICATIONS + "Delete";

// Version
const VERSION = API + "Version/";
export const GET_VERSION_BD = VERSION + "VersionBd";
export const GET_VERSION_API = HOST + "version-api.txt";
export const GET_VERSION_UI = HOST + "version-ui.txt";

// Stimulsoft
const STIMULSOFT = API + "Stimulsoft/";
export const STIMULSOFT_GET_FILE_RAPPORT = STIMULSOFT + "GetFileRapport";
export const STIMULSOFT_GET_FILE_REQUIS = STIMULSOFT + "GetFileRequis";

//#region Paramètres du Logiciel
const PARAMETRES_LOGICIEL = API + "ParametresLogiciel/";
export const LOAD_PL_SEC_GRP_USAGERS = PARAMETRES_LOGICIEL + "LoadPLSecGrpUsagers";
export const MAJ_PL_SEC_GRP_USAGERS = PARAMETRES_LOGICIEL + 'MajPLSecGrpUsagers';
export const LOAD_PL_SEC_SECURITES = PARAMETRES_LOGICIEL + "LoadPLSecSecurites";
export const MAJ_PL_SEC_SECURITES = PARAMETRES_LOGICIEL + "MajPLSecSecurites";
export const COPY_PL_SEC_SECURITES = PARAMETRES_LOGICIEL + "CopyPLSecSecurites";
export const LOAD_PL_COMM_EVENEMENTS = PARAMETRES_LOGICIEL + "LoadPlCommEvenements";
export const MAJ_PL_COMM_EVENEMENT = PARAMETRES_LOGICIEL + "MajPlCommEvenement";
export const LOAD_PL_RAPPEL_AUTOS = PARAMETRES_LOGICIEL + "LoadPlRappelAutos";
export const MAJ_PL_RAPPELS_AUTOS = PARAMETRES_LOGICIEL + "MajPlRappelsAutos";
export const LOAD_HIST_COP_SECURITE = PARAMETRES_LOGICIEL + "LoadHistCopSecurite";
export const LOAD_CONSULT_TACHES_NIGHT_WORKER = PARAMETRES_LOGICIEL + "LoadConsultTachesNW";
export const LOAD_TRAITEMENTS_IV_REMINDS = PARAMETRES_LOGICIEL + "LoadTraitementsIVReminds";
export const LOAD_PRIORITE_DOC_CONVERTIR_PDF = PARAMETRES_LOGICIEL + "LoadPrioriteDocConvertirPdf";
export const UPDATE_VM_DOC_CONVERSION_ERRORS_DETAILS = PARAMETRES_LOGICIEL + "UpdateDocConversionErrorDetails";
export const DOC_CONV_PDF_SET_PRIORITY = PARAMETRES_LOGICIEL + "SetPriority";
export const LOAD_ETAT_SERVICES = PARAMETRES_LOGICIEL + "LoadEtatServices";
export const POST_RESTART_SERVICE = PARAMETRES_LOGICIEL + "RestartService";
export const LOAD_MEMBRES_GROUPE_AD = PARAMETRES_LOGICIEL + "LoadMembresGroupeAd";
//#endregion


//#region Liste de Distribution
const LISTE_DISTRIBUTION = API + "ListeDistribution/"
export const LOAD_LISTE_DISTRIBUTION = LISTE_DISTRIBUTION + "LoadListeDistribuition"
export const DUPLICATE_LISTE_DISTRIBUTION = LISTE_DISTRIBUTION + "DuplicateListeDistribuition"
export const MAJ_LISTE_DISTRIBUTION = LISTE_DISTRIBUTION + "MajListeDistribuition"
//#endregion
